import React from 'react';
import Highlight from 'react-highlight';
import {Button} from '../../lib/';

const pattern = '.w-{scale}';
const patternResponsive = '.w-{screen}-{scale}';
const widthUtils = () => (
  <div>
    <h1 className="mb-3">Width utility classes</h1>
    <p className="mb-1">The width of the components in VSG Design System can be modified
    by utility classes.
    </p>
    <p className="mb-1">A predefined set of percent-relative widths are available through the
     following pattern: <b><i>{pattern}</i></b> . The starting 'w' stands for 'width'.</p>
    <p className="mb-1">The scale is a value among:</p>
    <ul className="basic-list mb-1">
      <li>25</li>
      <li>50</li>
      <li>75</li>
      <li>100</li>
      <li>auto</li>
    </ul>
    <p className="mb-5">Each of the numbers is percentage representation of the width. The 
      value <i>auto</i> will set the width of the target component or element automatically.</p>

    <div className="mb-5">
      <Button className="w-auto">Auto width</Button>
      <br/>
      <br/>
      <Button className="w-25">Width: 25%</Button>
      <br/>
      <br/>
      <Button className="w-50">Width: 50%</Button>
      <br/>
      <br/>
      <Button className="w-75">Width: 75%</Button>
      <br/>
      <br/>
      <Button className="w-100">Width: 100%</Button>
      <br/>
      <br/>
    </div>
    <Highlight className="React mb-4">
      {
        `
import React from 'react';

const Example = (props) => {
    return (
      // The space between the components above is for demo purpose
    <div>
      <Button className="w-auto">Auto width</Button>
      <Button className="w-25">Width: 25%</Button>
      <Button className="w-50">Width: 50%</Button>
      <Button className="w-75">Width: 75%</Button>
      <Button className="w-100">Width: 100%</Button>
    </div>
    );
}

export default Example; 
`
      }
    </Highlight>

    <h3 className="mb-3">Responsive width utiliies</h3>
    <p className="mb-1">The width utility class pattern can be extended with media queries
     support: <b><i>{patternResponsive}</i></b> .
     <br />
      The screen units are among:
    </p>
    <ul className="basic-list mb-5">
      <li>sm - Mobile screens</li>
      <li>md - Tablet screens</li>
      <li>lg - Desktop screens</li>
    </ul>
    <p className="mb-1">Consider the following example:</p>
    <Highlight className="React mb-1">
      {
        `<Button className="w-sm-50 w-md-auto w-lg-100">Demo</Button>`
      }
    </Highlight>
    <p>The Button will be 100% wide on large screens. On smaller displays (like tablet) the width will 
      be <i>auto</i> and on the smallest displays (mobile) the width will be 50%. </p>
  </div>
);
    
export default widthUtils;