import React from 'react';
import Highlight from 'react-highlight';
import { NavSteps } from '../../lib';


const navSteps = () => {
    return (
        <div>
            <h1 className='mb-3'>NavSteps</h1>

            <div className='mb-2'>
                <NavSteps className="mb-3" onLastStep={true} steps={[
                    { text: 'Application Info', path: '#' },
                    { text: 'Purpose Product', path: '#' },
                    { text: 'Borrower', path: '#' },
                    { text: 'Guarantor', path: '#' },
                    { text: 'Reference', path: '#' },
                    { text: 'Status ', path: '#' }
                ]} />

                <NavSteps className="mb-3" steps={[
                    { text: 'Application Info', path: '#' },
                    { text: 'Purpose Product', path: '#' },
                    { text: 'Borrower', path: '#' },
                ]} />

                <NavSteps className="mb-3" steps={[
                    { text: '', path: '' },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                ]} />

                <NavSteps className="mb-3" onLastStep={true} steps={[
                    { text: '', path: '' },
                    { path: '#' },
                    { path: '#', isActive: true },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                    { path: '#' },
                ]} />
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { NavSteps } from 'vsg-design-system/NavSteps';

const Example = (props) => {
    return (
    <div>
        <NavSteps onLastStep={true} steps={[
            { text: 'Application Info', path: '#' },
            { text: 'Purpose Product', path: '#' },
            { text: 'Borrower', path: '#' },
            { text: 'Guarantor', path: '#' },
            { text: 'Reference', path: '#' },
            { text: 'Status ', path: '#' }
        ]} />

        <NavSteps steps={[
            { text: 'Application Info', path: '#' },
            { text: 'Purpose Product', path: '#' },
            { text: 'Borrower', path: '#' },
        ]} />

        <NavSteps steps={[
            { text: '', path: '' },
            { path: '#' },
            { path: '#' },
            { path: '#' },
            { path: '#' },
            { path: '#' },
            { path: '#' },
            { path: '#' },
        ]} />

        <NavSteps onLastStep={true} steps={[
            { text: '', path: '' },
            { path: '#' },
            { path: '#', isActive: true },
            { path: '#' },
            { path: '#' },
            { path: '#' },
            { path: '#' },
            { path: '#' },
        ]} />
    </div>
    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>steps</td>
                        <td>array of objects with keys: "text", "path", "isActive", "dataUI"</td>
                        <td className="text-center">-</td>
                        <td>Set steps ( links )</td>
                    </tr>
                    <tr>
                        <td>onLastStep</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Hides the last box with three dots</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default navSteps;