import React from 'react';
import Highlight from 'react-highlight';
import {
    Input,
    MaskedInput,
    Textarea,
    Checkbox,
    Radio,
    Select,
    FormGroup,
    ZipCode,
    FormSection, Row, Col,
    IntegerInput,
    MultipleFormGroup
} from '../../lib';
import { faPhone, faDollarSign, faPercent, faMapPin, faCreditCard } from '@fortawesome/free-solid-svg-icons';

const forms = () => {
    const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    const creditCardMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    return <div>
        <h1 className='mb-3'>Forms</h1>

        <h2 className='mb-2'>Description</h2>
        <p className='mb-4'>Examples and usage guidelines for form controls, layout options for creating a wide variety of forms.<br />
            Form control components in QD Design System extend the native form control elements.
        </p>

        <h3 className='mb-3'>Input</h3>

        <div className='mb-2'>
            <Input className="mb-2" type="text" name="name" />
            <Input className="mb-2" type="number" name="name" />
            <Input className="mb-2" type="password" name="pass" />
            <Input className="mb-2" type="text" name="name" placeholder="Placeholder" disabled />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Input } from 'vsg-design-system/Input';

const Example = (props) => {
    return (
    <div>
        <Input type="text" name="name" />
        <Input type="number" name="name" />
        <Input type="password" name="pass" />
        <Input type="text" name="name" placeholder="Placeholder" disabled />
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>all native input attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native input attributes like: "type", "disabled", "name"...</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>IntegerInput</h3>

        <div className='mb-2'>
            <IntegerInput className="mb-2" />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { IntegerInput } from 'vsg-design-system/IntegerInput';

const Example = (props) => {
    return (
    <div>
        <IntegerInput />
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>all native input attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td><strong>Except "type"</strong>, you can use all other native input attributes: "disabled", "name", "placeholder"...</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>Input with mask</h3>
        <p className='mb-3'>QD Design System MaskedInput uses "Text Mask" library. Check demo <a href="https://text-mask.github.io/text-mask/" target="_blank" rel="noopener noreferrer">here</a> and documentation <a target="_blank" rel="noopener noreferrer" href="https://github.com/text-mask/text-mask/tree/master/react#readme">here</a>.</p>
        <div className='mb-2'>
            <MaskedInput
                className="mb-2"
                type='phone'
                mask={usPhoneCountryCodeMask}
                icon={faPhone}
            />
            <MaskedInput
                className="mb-2"
                type='number'
                icon={faDollarSign}
                onChange={(e) => console.log("displayed value:" + e.target.value, "formatted value:" + e.target.formattedValue)}
            />
            <MaskedInput
                className="mb-2"
                type='percent'
                icon={faPercent}
            />
            <MaskedInput
                className="mb-2"
                mask={zipCodeMask}
                type='zip-code'
                icon={faMapPin}
            />
            <MaskedInput
                className="mb-2"
                mask={creditCardMask}
                type='credit-card'
                icon={faCreditCard}
            />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { MaskedInput } from 'vsg-design-system/MaskedInput';
import { IconsSolid } from 'vsg-design-system/IconsSolid';

const Example = (props) => {
const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\\d/, /\\d/, ')', ' ', /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/];
const zipCodeMask = [/\\d/, /\\d/, /\\d/, /\\d/, /\\d/];
const creditCardMask = [/\\d/, /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/];
    return (
    <div>
        <MaskedInput
            type='phone'
            mask={usPhoneCountryCodeMask}
            icon={IconsSolid.faPhone}
        />
        <MaskedInput
            type='number'
            icon={IconsSolid.faDollarSign}
            onChange={(e)=>console.log("displayed value:" + e.target.value, "formatted value:" + e.target.formattedValue)}
        />
        <MaskedInput
            type='percent'
            icon={IconsSolid.faPercent}
        />
        <MaskedInput
            type='zip-code'
            mask={zipCodeMask}
            icon={IconsSolid.faMapPin}
        />
        <MaskedInput
            type='credit-card'
            mask={creditCardMask}
            icon={IconsSolid.faCreditCard}
      />
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>type</td>
                    <td>"phone", "number", "percent", "zip-code", "credit-card"</td>
                    <td>number</td>
                    <td>Set style for alignment and for some types and length of the value.</td>
                </tr>
                <tr>
                    <td>mask</td>
                    <td>regex</td>
                    <td>decimal number with separator comma</td>
                    <td>Set input pattern. Use <strong>only</strong> for type: "phone", "zip-code", "credit-card"</td>
                </tr>
                <tr>
                    <td>icon</td>
                    <td>font awesome icon</td>
                    <td className='text-center'>-</td>
                    <td>Set icon for the input.</td>
                </tr>
                <tr>
                    <td>native input attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td><strong>Except "type"</strong>, you can use all other native input attributes: "disabled", "name", "placeholder"...</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>Zip Code</h3>
        <p className='mb-3'>Zip Code component provides a MaskedInput, which can switch the mask pattern to match 5 or 9-digit Zip code type.</p>
        <div className='mb-2'>
            <ZipCode
                name="zipCodeFirst"
                isRequired={true}
                className="mb-2"
            />
            <ZipCode
                name="zipCodeSecond"
                className="mb-2"
            />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { ZipCode } from 'vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <ZipCode 
            name="zipCodeFirst"
            isRequired={true}
        />
        <ZipCode 
            name="zipCodeSecond"
            /* 
                If value property is provided to the component,
                a callback function can be executed when the
                zip code format is changed to five digits.
                The returned value is the nine-digit value, trimmed to
                the fifth number.
                
                onSelectFiveDigits={(val)=>console.log(val)}
            */
        />
    </div>
    );
}

export default Example; 
    `}
        </Highlight>

        <h3 className='mb-3'>Checkbox</h3>

        <div className='mb-2'>
            <Checkbox name="vehicle1" value="Bike">I have a bike</Checkbox>
            <Checkbox name="vehicle2" value="Car">I have a car</Checkbox>
            <Checkbox name="vehicle3" value="Boat">I have a boat</Checkbox>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Checkbox } from 'vsg-design-system/Checkbox';

const Example = (props) => {
    return (
    <div>
        <Checkbox name="vehicle1" value="Bike">I have a bike</Checkbox>
        <Checkbox name="vehicle2" value="Car">I have a car</Checkbox>
        <Checkbox name="vehicle3" value="Boat">I have a boat</Checkbox>
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>all native input type checkbox attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native input type checkbox attributes like: "name", "value", "checked"...</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>Radio</h3>

        <div className='mb-2'>
            <Radio name="gender" value="male">Male</Radio>
            <Radio name="gender" value="female">Female</Radio>
            <Radio name="gender" value="other">Other</Radio>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Radio } from 'vsg-design-system/Radio';

const Example = (props) => {
    return (
    <div>
        <Radio name="gender" value="male">Male</Radio>
        <Radio name="gender" value="female">Female</Radio>
        <Radio name="gender" value="other">Other</Radio>
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>all native input type radio attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native input type radio attributes like: "name", "value", "checked"...</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>Checkbox and Radio inline</h3>
        <p className="mb-3">Group checkboxes or radios inline by adding utility class: "d-inline-block".</p>

        <div className='mb-2'>
            <div className="mb-1">
                <Checkbox className="d-inline-block" name="vehicle1-inline" value="Bike">I have a bike</Checkbox>
                <Checkbox className="d-inline-block" name="vehicle2-inline" value="Car">I have a car</Checkbox>
                <Checkbox className="d-inline-block" name="vehicle3-inline" value="Boat">I have a boat</Checkbox>
            </div>
            <div>
                <Radio className="d-inline-block" name="gender-inline" value="male">Male</Radio>
                <Radio className="d-inline-block" name="gender-inline" value="female">Female</Radio>
                <Radio className="d-inline-block" name="gender-inline" value="other">Other</Radio>
            </div>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Radio} from 'vsg-design-system/Radio';
import { Checkbox } from 'vsg-design-system/Checkbox';

const Example = (props) => {
    return (
    <div>
        <Checkbox className="d-inline-block" name="vehicle1-inline" value="Bike">I have a bike</Checkbox>
        <Checkbox className="d-inline-block" name="vehicle2-inline" value="Car">I have a car</Checkbox>
        <Checkbox className="d-inline-block" name="vehicle3-inline" value="Boat">I have a boat</Checkbox>
         
        <Radio className="d-inline-block" name="gender-inline" value="male">Male</Radio>
        <Radio className="d-inline-block" name="gender-inline" value="female">Female</Radio>
        <Radio className="d-inline-block" name="gender-inline" value="other">Other</Radio>
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <h3 className='mb-3'>Textarea</h3>
        <div className='mb-2'>
            <Textarea rows="4" />
        </div>
        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Textarea } from 'vsg-design-system/Textarea';

const Example = (props) => {
    return (
    <div>
        <Textarea rows="4" />
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>all native textarea attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native textarea attributes like: "rows", "disabled", "name"...</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>Select</h3>
        <div className='mb-2'>
            <Select className="mb-2"
                placeholder="Select..."
                options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }]}
            >
            </Select>
            <Select className="mb-2"
                options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }]}
            >
            </Select>
            <Select
                placeholder="Select..."
                isLoading
                options={[
                    { value: 'chocolate', label: 'Chocolate' }]}
            />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Select } from 'vsg-design-system/Select';

const Example = (props) => {
    return (
    <div>
        <Select 
            placeholder="Select..."
            options={[
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
            ]}>
        </Select>

        <Select
            options={[
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }]}
         >
        </Select>

        <Select
            placeholder="Select..."
            isLoading
            options={[
                { value: 'chocolate', label: 'Chocolate' }]}
        />
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>options</td>
                    <td>array of objects.<br />
                        object must have props: <strong>"value","label"</strong>
                    </td>
                    <td className='text-center'>-</td>
                    <td>Set select value and options.</td>
                </tr>
                <tr>
                    <td>isLoading</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Indicates if the select should be displayed in loading state.</td>
                </tr>
                <tr>
                    <td>all native select attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native select attributes like: "name", "disabled", "placeholder"...</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>Form Group</h3>
        <p className='mb-3'>Wrapping your form controls in this component you can set <strong>label</strong> and <strong>tooltip</strong> to them. Check documentation for Tooltip <strong>here</strong> to see how to use it.</p>

        <div className='mb-2'>
            <FormGroup label='Input' isRequired={true} className="mb-2">
                <Input />
            </FormGroup>
            <FormGroup label='Masked Input' className="mb-2">
                <MaskedInput
                    className="mb-2"
                    type='phone'
                    mask={usPhoneCountryCodeMask}
                    icon={faPhone}
                />
            </FormGroup>
            <FormGroup label='Select' isRequired={true} className="mb-2">
                <Select
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' }]}
                >
                </Select>
            </FormGroup>
            <FormGroup label='Radio' isBold={true} className="mb-2">
                <Radio className="d-inline-block" name="agree" value="yes">Yes</Radio>
                <Radio className="d-inline-block" name="agree" value="no">No</Radio>
            </FormGroup>
            <FormGroup label='Checkbox' isRequired={true} className="mb-2" isValid={false} validationMessage="Invalid field">
                <Checkbox className="d-inline-block" name="vehicle1-inline" value="Bike">I have a bike</Checkbox>
                <Checkbox className="d-inline-block" name="vehicle2-inline" value="Car">I have a car</Checkbox>
                <Checkbox className="d-inline-block" name="vehicle3-inline" value="Boat">I have a boat</Checkbox>
            </FormGroup>
            <FormGroup label='Name' className="mb-2" hoverHelp="Full name" isBold={true}>
                <Input />
            </FormGroup>
            <FormGroup label="Radio" isRequired={true} className="mb-2" isValid={false} validationMessage="Choose field">
                <Radio className="d-inline-block" name="agree" value="yes">Yes</Radio>
                <Radio className="d-inline-block" name="agree" value="no">No</Radio>
            </FormGroup>
            <FormGroup label="Input" isRequired={true} isValid={false} validationMessage="This field is required">
                <Input />
            </FormGroup>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { FormGroup } from 'vsg-design-system/FormGroup';
import { Select } from 'vsg-design-system/Select';
import { Input } from 'vsg-design-system/Input';
import { MaskedInput } from 'vsg-design-system/MaskedInput';
import { Radio } from 'vsg-design-system/Radio';
import { Checkbox } from 'vsg-design-system/Checkbox';

const Example = (props) => {
    return (
    <div>
            <FormGroup label='Input' isRequired={true}>
                <Input />
            </FormGroup>
            <FormGroup label='Masked Input'>
                <MaskedInput
                    type='phone'
                    mask={usPhoneCountryCodeMask}
                    icon={faPhone}
                />
            </FormGroup>
            <FormGroup label='Select' isRequired={true}>
                <Select
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' }]}
                >
                </Select>
            </FormGroup>
            <FormGroup label='Radio' isBold={true} >
                <Radio className="d-inline-block" name="agree" value="yes">Yes</Radio>
                <Radio className="d-inline-block" name="agree" value="no">No</Radio>
            </FormGroup>
            <FormGroup label='Checkbox' isRequired={true} isValid={false} validationMessage="Invalid field">
                <Checkbox className="d-inline-block" name="vehicle1-inline" value="Bike">I have a bike</Checkbox>
                <Checkbox className="d-inline-block" name="vehicle2-inline" value="Car">I have a car</Checkbox>
                <Checkbox className="d-inline-block" name="vehicle3-inline" value="Boat">I have a boat</Checkbox>
            </FormGroup>

            // with tooltip
            <FormGroup label='Name' hoverHelp="Full name" isBold={true} >
                 <Input />
            </FormGroup>
            <FormGroup label="Radio" isRequired={true} isValid={false} validationMessage="Choose field">
                <Radio className="d-inline-block" name="agree" value="yes">Yes</Radio>
                <Radio className="d-inline-block" name="agree" value="no">No</Radio>
            </FormGroup>
            <FormGroup label="Input" isRequired={true} isValid={false} validationMessage="This field is required">
                <Input />
            </FormGroup>
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3" id="formGroupProps">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>label</td>
                    <td>string</td>
                    <td className='text-center'>-</td>
                    <td>Sets label title to the group.<br />
                        <strong>Notice:</strong> Always set value to this prop if you have inputs on same row, to have them with equal height.
                    </td>
                </tr>
                <tr>
                    <td>isHidden</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Determines whether the content of the FormGroup is hidden.</td>
                </tr>
                <tr>
                    <td>isRequired</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Hides the '(optional)' text.</td>
                </tr>
                <tr>
                    <td>isBold</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Makes the label bold.</td>
                </tr>
                <tr>
                    <td>hoverHelp</td>
                    <td>string</td>
                    <td className='text-center'>-</td>
                    <td>Set tooltip icon next to the label. <br />
                        <strong>Required:</strong> label prop to be set with value.
                    </td>
                </tr>
                <tr>
                    <td>isValid</td>
                    <td>boolean</td>
                    <td>true</td>
                    <td>Show validation message text.</td>
                </tr>
                <tr>
                    <td>validationMessage</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set validation message text.</td>
                </tr>
            </tbody>
        </table>

        <h3 className='mb-3'>Multiple Form Group</h3>
        <p></p>
        <div className='mb-2'>
            <Row>
                <MultipleFormGroup
                    formGroupProps={{
                        label: 'Choose expiry date'
                    }}
                    groupColProps={{
                        lg: '3',
                    }}
                    groupElements={[
                        {
                            element: <Input type="number" placeholder="YYYY" />,
                            elementColProps: {
                                lg: '6',
                                className: 'mb-2'
                            }
                        },
                        {
                            element: <Select
                                options={[
                                    { value: 'Jan', label: 'Jan' },
                                    { value: 'Feb', label: 'Feb' },
                                    { value: 'Mar', label: 'Mar' },
                                    { value: 'Apr', label: 'Apr' },
                                    { value: 'May', label: 'May' },
                                    { value: 'June', label: 'June' },
                                    { value: 'July', label: 'July' },
                                    { value: 'Aug', label: 'Aug' },
                                    { value: 'Sept', label: 'Sept' },
                                    { value: 'Oct', label: 'Oct' },
                                    { value: 'Nov', label: 'Nov' },
                                    { value: 'Dec', label: 'Dec' },
                                ]}
                            />,
                            elementColProps: {
                                lg: '6',
                                className: 'mb-2'
                            }
                        }
                    ]}
                />

                <MultipleFormGroup
                    formGroupProps={{
                        isRequired: true,
                        label: 'Multiple From group example'
                    }}
                    groupColProps={{
                        lg: '9'
                    }}
                    groupElements={[
                        {
                            element: <Input className="invalid" />,
                            elementColProps: {
                                lg: '3',
                                className: 'mb-2'
                            },
                            isValid: false,
                            validationMessage: "Invalid Input"
                        },
                        {
                            element: <Select
                                className="invalid"
                                options={[
                                    { value: 'one', label: 'Option One' },
                                    { value: 'two', label: 'Option Two' },
                                    { value: 'other', label: 'Other' },
                                ]}
                            />,
                            elementColProps: {
                                lg: '4',
                                className: 'mb-2'
                            },
                            isValid: false,
                            validationMessage: "Invalid Select"
                        },
                        {
                            element: <Input />,
                            elementColProps: {
                                lg: '5',
                                className: 'mb-2'
                            }
                        },
                    ]}
                />
            </Row>
        </div>

        <div className='mb-2'>
            <p className="mb-2"><strong>Example MultipleFormGroup wrapped in Col</strong></p>
            <Row>
                <Col lg='3'>
                    <MultipleFormGroup
                        className="px-0"
                        formGroupProps={{
                            label: 'Choose expiry date'
                        }}
                        groupColProps={{
                            xs: ''
                        }}
                        groupElements={[
                            {
                                element: <Input type="number" placeholder="YYYY" />,
                                elementColProps: {
                                    lg: '6',
                                    className: 'mb-2'
                                }
                            },
                            {
                                element: <Select
                                    options={[
                                        { value: 'Jan', label: 'Jan' },
                                        { value: 'Feb', label: 'Feb' },
                                        { value: 'Mar', label: 'Mar' },
                                        { value: 'Apr', label: 'Apr' },
                                        { value: 'May', label: 'May' },
                                        { value: 'June', label: 'June' },
                                        { value: 'July', label: 'July' },
                                        { value: 'Aug', label: 'Aug' },
                                        { value: 'Sept', label: 'Sept' },
                                        { value: 'Oct', label: 'Oct' },
                                        { value: 'Nov', label: 'Nov' },
                                        { value: 'Dec', label: 'Dec' },
                                    ]}
                                />,
                                elementColProps: {
                                    lg: '6',
                                    className: 'mb-2'
                                }
                            }
                        ]}
                    />
                </Col>
            </Row>
        </div>


        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { MultipleFormGroup } from 'vsg-design-system/FormGroup';
import { Select } from 'vsg-design-system/Select';
import { Row } from 'vsg-design-system/Select';

const Example = (props) => {
    return (
    <Row>
        <MultipleFormGroup
        formGroupProps={{
            label: 'Choose expiry date'
        }}
        groupColProps={{
            lg: '3',
        }}
        groupElements={[
            {
                element: <Input type="number" placeholder="YYYY" />,
                elementColProps: {
                    lg: '6',
                    className: 'mb-2'
                }
            },
            {
                element: <Select
                    options={[
                        { value: 'Jan', label: 'Jan' },
                        { value: 'Feb', label: 'Feb' },
                        { value: 'Mar', label: 'Mar' },
                        { value: 'Apr', label: 'Apr' },
                        { value: 'May', label: 'May' },
                        { value: 'June', label: 'June' },
                        { value: 'July', label: 'July' },
                        { value: 'Aug', label: 'Aug' },
                        { value: 'Sept', label: 'Sept' },
                        { value: 'Oct', label: 'Oct' },
                        { value: 'Nov', label: 'Nov' },
                        { value: 'Dec', label: 'Dec' },
                    ]}
                />,
                elementColProps: {
                    lg: '6',
                    className: 'mb-2'
                }
            }
        ]}
     />

        <MultipleFormGroup
            formGroupProps={{
                isRequired: true,
                label: 'Multiple From group example'
            }}
            groupColProps={{
                lg: '9'
            }}
            groupElements={[
                {
                    element: <Input className="invalid" />,
                    elementColProps: {
                        lg: '3',
                        className: 'mb-2'
                    },
                    isValid: false,
                    validationMessage: "Invalid Input"
                },
                {
                    element: <Select
                        className="invalid"
                        options={[
                            { value: 'one', label: 'Option One' },
                            { value: 'two', label: 'Option Two' },
                            { value: 'other', label: 'Other' },
                        ]}
                    />,
                    elementColProps: {
                        lg: '4',
                        className: 'mb-2'
                    },
                    isValid: false,
                    validationMessage: "Invalid Select"
                },
                {
                    element: <Input />,
                    elementColProps: {
                        lg: '5',
                        className: 'mb-2'
                    }
                },
            ]}
        />



       //Example MultipleFormGroup wrapped in Col

        <Col lg='3'>
            <MultipleFormGroup
                className="px-0"
                formGroupProps={{
                    label: 'Choose expiry date'
                }}
                groupColProps={{
                    xs: ''
                }}
                groupElements={[
                    {
                        element: <Input type="number" placeholder="YYYY" />,
                        elementColProps: {
                            lg: '6',
                            className: 'mb-2'
                        }
                    },
                    {
                        element: <Select
                            options={[
                                { value: 'Jan', label: 'Jan' },
                                { value: 'Feb', label: 'Feb' },
                                { value: 'Mar', label: 'Mar' },
                                { value: 'Apr', label: 'Apr' },
                                { value: 'May', label: 'May' },
                                { value: 'June', label: 'June' },
                                { value: 'July', label: 'July' },
                                { value: 'Aug', label: 'Aug' },
                                { value: 'Sept', label: 'Sept' },
                                { value: 'Oct', label: 'Oct' },
                                { value: 'Nov', label: 'Nov' },
                                { value: 'Dec', label: 'Dec' },
                            ]}
                        />,
                        elementColProps: {
                            lg: '6',
                            className: 'mb-2'
                        }
                    }
                ]}
            />
        </Col>
    </Row>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>formGroupProps</td>
                    <td>Object with <a href="#formGroupProps"><strong>FormGroup props</strong></a> without validationMessage and isValid</td>
                    <td className='text-center'>-</td>
                    <td>Sets Form Group props</td>
                </tr>
                <tr>
                    <td>groupColProps</td>
                    <td>
                        Object with props:<br />
                        ReactStrap props <a target="_blank" href='https://reactstrap.github.io/components/layout/' rel="noopener noreferrer"><strong>here</strong></a><br />
                        "isHidden": "boolean" - hide column,
                        "className": "string"
                    </td>
                    <td>xs="12"</td>
                    <td>Sets grid col classes to the wrapping element of the form controls.</td>
                </tr>
                <tr>
                    <td>groupElements</td>
                    <td>
                        Array of objects with props:<br />
                        "element": JSX.Element,<br />
                        "elementColProps": object with props like <strong>groupColProps</strong><br />
                        "validationMessage": "string",
                        "isValid": "boolean"
                    </td>
                    <td className="text-center">lg="6" xs="12"</td>
                    <td>Set the form elements.</td>
                </tr>
                <tr>
                    <td>className</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set class name to the Form group wrapper.</td>
                </tr>
            </tbody>
        </table>


        <h3 className='mb-3'>Hide fields</h3>
        <p className='mb-3'>You can use 'isHidden' from 'Col' component to hide a field.</p>
        <Row>
            <Col lg="3" isHidden={true}>
                <FormGroup label="Input 1" className="mb-2">
                    <Input />
                </FormGroup>
            </Col>
            <Col lg="3">
                <FormGroup label="Input 2" className="mb-2">
                    <Input />
                </FormGroup>
            </Col>
            <Col lg="3" isHidden={true}>
                <FormGroup label="Input 3" className="mb-2">
                    <Input />
                </FormGroup>
            </Col>
            <Col lg="3">
                <FormGroup label="Input 4" className="mb-2">
                    <Input />
                </FormGroup>
            </Col>
        </Row>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { FormGroup } from 'vsg-design-system/FormGroup';
import { Input } from 'vsg-design-system/Input';
import { Row } from 'vsg-design-system/Row';
import { Col } from 'vsg-design-system/Col';

const Example = (props) => {
    return (
        <Row>
            <Col lg="3" isHidden={true}>
                <FormGroup label="Input 1">
                    <Input />
                </FormGroup>
            </Col>
            <Col lg="3">
                <FormGroup label="Input 2">
                    <Input />
                </FormGroup>
            </Col>
            <Col lg="3" isHidden={true}>
                <FormGroup label="Input 3">
                    <Input />
                </FormGroup>
            </Col>
            <Col lg="3">
                <FormGroup label="Input 4">
                    <Input />
                </FormGroup>
            </Col>
        </Row>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isHidden</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Determines whether the content of the Col is hidden.</td>
                </tr>
            </tbody>
        </table>


        <h3 className='mb-3'>Form Section</h3>
        <p className='mb-3'>Separate your content with border. Helps you to visually group form control components.</p>

        <FormSection>
            <h6 className="mb-2">1. Form Section title</h6>
            <Row>
                <Col lg="3">
                    <FormGroup label='Input' className="mb-2">
                        <Input />
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup label='Select' className="mb-2">
                        <Select
                            options={[
                                { value: 'chocolate', label: 'Chocolate' },
                                { value: 'strawberry', label: 'Strawberry' },
                                { value: 'vanilla', label: 'Vanilla' }]}
                        >
                        </Select>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup label='Masked Input' className="mb-2">
                        <MaskedInput
                            className="mb-2"
                            type='phone'
                            mask={usPhoneCountryCodeMask}
                            icon={faPhone}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </FormSection>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { FormGroup } from 'vsg-design-system/FormGroup';
import { Select } from 'vsg-design-system/Select';
import { Input } from 'vsg-design-system/Input';
import { MaskedInput } from 'vsg-design-system/MaskedInput';
import { Row } from 'vsg-design-system/Row';
import { Col } from 'vsg-design-system/Col';

const Example = (props) => {
    return (
        <FormSection>
            <h6 className="mb-2">1. Form Section title</h6>
            <Row>
                <Col lg="3">
                    <FormGroup label='Input' className="mb-2">
                        <Input />
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup label='Select' className="mb-2">
                        <Select
                            options={[
                                { value: 'chocolate', label: 'Chocolate' },
                                { value: 'strawberry', label: 'Strawberry' },
                                { value: 'vanilla', label: 'Vanilla' }]}
                        >
                        </Select>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup label='Masked Input' className="mb-2">
                        <MaskedInput
                            className="mb-2"
                            type='phone'
                            mask={usPhoneCountryCodeMask}
                            icon={faPhone}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </FormSection>
    );
}

export default Example; 
`
            }
        </Highlight>
    </div>;
}

export default forms;