import React from 'react';
import Highlight from 'react-highlight';

const textUtils = () => (
  <div>
    <h1 className="mb-3">Text utility classes</h1>
    <div className="mb-5">
      <p className="text-center">Text center</p>
      <p className="text-primary">Text primary</p>
      <p className="text-dark">Text dark</p>
      <p className="text-success">Text success</p>
      <p className="text-danger">Text danger</p>
      <p className="text-nowrap">Text nowrap</p>
    </div>
    <Highlight className="React mb-4">
      {
        `
import React from 'react';

const Example = (props) => {
  return (
  <div>
      <p className="text-center">Text center</p>
      <p className="text-primary">Text primary</p>
      <p className="text-dark">Text dark</p>
      <p className="text-success">Text success</p>
      <p className="text-danger">Text danger</p>
      <p className="text-nowrap">Text nowrap</p>
  </div>
  );
}

export default Example; 
`
      }
    </Highlight>
  </div>
);
export default textUtils;