import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Button,
  NavSteps,
  Section,
  ActionsWrapper
} from '../../lib/index';

const pageTen = () => {

  return (
    <div>
      <NavSteps className="mb-2" steps={[
        { text: 'Info', path: '/views/pages/1' },
        { text: 'Request', path: '/views/pages/2' },
        { text: 'Borrower', path: '/views/pages/3' },
        { text: 'Business References', path: '/views/pages/5' },
        { text: 'Collateral', path: '/views/pages/6' },
        { text: 'Debts / Deposits', path: '/views/pages/7' },
        { text: 'Declarations', path: '/views/pages/8' },
        { text: 'Wrap Up', path: '/views/pages/9' },
        { text: 'Review Application', path: '#' },

      ]} />

      <Section className="mb-4" title="Request" link={{ label: 'Change', path: '#' }}>
        <h6 className="text-center mb-2">Purpose and Product</h6>
        <table className="preview-table mb-4">
          <tbody>
            <tr>
              <td><p>Purpose of Loan</p></td>
              <td><p>Working Capital</p></td>
            </tr>
            <tr>
              <td><p>Product Type</p></td>
              <td><p>Credit Card</p></td>
            </tr>
            <tr>
              <td><p>Amount Requested</p></td>
              <td><p>$ 100,000</p></td>
            </tr>
            <tr>
              <td><p>Specific Reason</p></td>
              <td><p>new hotel</p></td>
            </tr>
            <tr>
              <td><p>Location of Business</p></td>
              <td><p>AK</p></td>
            </tr>
            <tr>
              <td><p>County of Business</p></td>
              <td><p>Aleutinas West Census Area</p></td>
            </tr>
            <tr>
              <td><p>Existing Deposit Customer</p></td>
              <td><p>Yes</p></td>
            </tr>
            <tr>
              <td><p>Date Business Started</p></td>
              <td><p>4/2016</p></td>
            </tr>
          </tbody>
        </table>
      </Section>

      <Section className="mb-4" title="Business References" link={{ label: 'Change', path: '#' }}>
        <h6 className="text-center mb-2">Accountant</h6>
        <table className="preview-table mb-4">
          <tbody>
            <tr>
              <td><p>Accountant</p></td>
              <td><p>Jhon Miller</p></td>
            </tr>
            <tr>
              <td><p>Email</p></td>
              <td><p>jhon@mailinator.com</p></td>
            </tr>
            <tr>
              <td><p>Citi/State/Zip Code</p></td>
              <td><p>CHICAGO, IL, 29829</p></td>
            </tr>
            <tr>
              <td><p>Phone Number</p></td>
              <td><p>(910) 128-4894</p></td>
            </tr>
          </tbody>
        </table>
        <h6 className="text-center mb-2">Attorney</h6>
        <table className="preview-table mb-4">
          <tbody>
            <tr>
              <td><p>Attorney</p></td>
              <td><p>Daniel Garcia</p></td>
            </tr>
            <tr>
              <td><p>Email</p></td>
              <td><p>daniel@mailinator.com</p></td>
            </tr>
            <tr>
              <td><p>Citi/State/Zip Code</p></td>
              <td><p>Los Angeles, CA, 28498</p></td>
            </tr>
            <tr>
              <td><p>Phone Number</p></td>
              <td><p>(219) 910-1030</p></td>
            </tr>
          </tbody>
        </table>
        <h6 className="text-center mb-2">Insurance Agent</h6>
        <table className="preview-table mb-4">
          <tbody>
            <tr>
              <td><p>Insurance Agent</p></td>
              <td><p>Amy Sater</p></td>
            </tr>
            <tr>
              <td><p>Email</p></td>
              <td><p>amy@mailinator.com</p></td>
            </tr>
            <tr>
              <td><p>Citi/State/Zip Code</p></td>
              <td><p>Bronx, NY, 92919</p></td>
            </tr>
            <tr>
              <td><p>Phone Number</p></td>
              <td><p>(219) 910-1030</p></td>
            </tr>
          </tbody>
        </table>
      </Section>

      <Section>
        <ActionsWrapper className="mb-1">
          <NavLink to={`/views/pages/11`}><Button>Submit Applicant and Continue</Button></NavLink>
        </ActionsWrapper>
      </Section>
    </div>
  );
}

export default pageTen;