import React from 'react';
import { NavLink } from 'react-router-dom';
import { faDollarSign, faPhone, faMapPin, faPercent } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  NavSteps,
  Section,
  FormGroup,
  Select,
  MaskedInput,
  Radio,
  Input,
  ActionsWrapper,
  Checkbox,
  Datepicker,
  Row, 
  Col
} from '../../lib/index';

const pageThree = () => {

  const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

  return (
    <div>
      <NavSteps className="mb-2" steps={[
        { text: 'Info', path: '/views/pages/1' },
        { text: 'Request', path: '/views/pages/2' },
        { text: 'Borrower', path: '#' },
      ]} />
      <Section title="Business Information">
        <Row>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Business Name">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Doing Business As (DBA), if applicable">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="mb-2" label="Street Address (No PO Boxes)">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="City">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="6" md="3">
            <FormGroup className="mb-2" label="State">
              <Select options={[
                { value: 'in', label: 'IN' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="6" md="3">
            <FormGroup className="mb-2" label="ZIP Code">
              <MaskedInput
                mask={zipCodeMask}
                type='zipCode'
                icon={faMapPin}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <Checkbox className="mb-2">Mailing Address (If different from above)</Checkbox>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Type of Business">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="6" md="3">
            <FormGroup className="mb-2" label="State Formed">
              <Select options={[
                { value: 'in', label: 'IN' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="6" md="3">
            <FormGroup className="mb-2" label="Telephone">
              <MaskedInput
                type='phone'
                mask={usPhoneCountryCodeMask}
                icon={faPhone}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Organization Type">
              <Select placeholder="Select..." options={[
                { value: 'in', label: 'IN' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="# of Employees">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Tax Entity Type">
              <Select placeholder="Select..." options={[
                { value: 'in', label: '1040' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Tax ID">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Website address">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="mb-2" label="Business Email Address (If different from Owner below)">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="4">
            <FormGroup className="mb-2" label="Most Recent Year End Date ">
              <Datepicker></Datepicker>
            </FormGroup>
          </Col>
          <Col sm="12" md="3">
            <FormGroup className="mb-2" label="Annual Sales">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
          <Col sm="12" md="3">
            <FormGroup className="mb-2" label="Net Income">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Business Checking Account #">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Re-enter Checking Account #">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-4" label="Business Checking Avg. Balance">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
        </Row>
      </Section>

      <Section title="Owner Information" className="mb-3">
        <Row>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="First Name">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Middle Name">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Last Name">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="SSN">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Street Address (No PO Boxes)">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="City">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="State">
              <Select options={[
                { value: 'in', label: 'IN' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="ZIP Code">
              <MaskedInput
                mask={zipCodeMask}
                type='zipCode'
                icon={faMapPin}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Telephone">
              <MaskedInput
                type='phone'
                mask={usPhoneCountryCodeMask}
                icon={faPhone}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Date of Birth">
              <Datepicker />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Employer Name">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Job Title">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Annual Income">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="% Ownership">
              <MaskedInput
                type='percent'
                icon={faPercent}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Are you a U.S. Citizen?">
              <Radio className="d-inline-block mr-1" name="deposit" value="yes">Yes</Radio>
              <Radio className="d-inline-block" name="deposit" value="no">No</Radio>
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Email Address">
              <Input type="email" />
            </FormGroup>
          </Col>
        </Row>
      </Section>

      <Section>
        <p className="body-2 mb-2">
          By clicking Continue, you agree and are providing consent under the Fair Credit Reporting Act authorizing Ovation Financial to obtain information from your personal credit profile or other information from Experian. You authorize Ovation Financial to obtain information solely to confirm your identity to avoid fraudulent transactions in your name.
            </p>
        <ActionsWrapper className="mb-1">
          <NavLink to={`/views/pages/4`}><Button>Continue</Button></NavLink>
        </ActionsWrapper>
      </Section>
    </div>
  );
}

export default pageThree;