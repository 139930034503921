import React from 'react';
import Highlight from 'react-highlight';
import { Button, TimeOutMessage, Row, Col } from '../../lib';

class TimeOutMessageDoc extends React.Component {
    state = {
        show: false,
    };

    render() {
        return (
            <div>
                <h1 className='mb-3'>TimeOutMessage</h1>

                <h2 className='mb-2'>Description</h2>
                <p className='mb-4'>The TimeOutMessage component is a dialog box/popup window that is displayed on top of the current page.</p>

                <Row className="mb-2">
                    <Col lg="3" className="mb-1">
                        <Button onClick={() => this.setState({ show: true })} >Show</Button>
                        <TimeOutMessage
                            isVisible={this.state.show}
                            title="Long inactivity detected"
                            subText="We will sign you out to keep your account safe"
                            seconds={66}
                            onTimeOut={() => {
                                this.setState({ show: false });
                                alert('Time Out - Sign out');
                            }}
                            buttons={[
                                <Button className="mb-2" onClick={() => this.setState({ show: false })}>I am here</Button>,
                                <Button btnType="secondary" onClick={() => this.setState({ show: false })}>Sign out now</Button>,
                            ]}
                        />
                    </Col>
                </Row>
                <div className='mb-2'>

                </div>
                <Highlight className="JS mb-4">
                    {
                        `
import React from 'react';
import { Button } from 'vsg-design-system/Button';
import { TimeOutMessage } from 'vsg-design-system/TimeOutMessage';
    
class Example extends React.Component {
    state = {
        show: false,
    }

    render(){
        return(
            <div>
                <Button onClick={() => this.setState({ show: true })} >Show</Button>
                <TimeOutMessage
                    isVisible={this.state.show}
                    title="Long inactivity detected"
                    subText="We will sign you out to keep your account safe"
                    seconds={66}
                    onTimeOut={() => {
                        this.setState({ show: false });
                        alert('Time Out - Sign out');
                    }}
                    buttons={[
                        <Button className="mb-2" onClick={() => this.setState({ show: false })}>I am here</Button>,
                        <Button btnType="secondary" onClick={() => this.setState({ show: false })}>Sign out now</Button>,
                    ]}
                />
            </div>
        )
    }
}

export default Example; 
    `
                    }
                </Highlight>

                <table className="doc-table mb-3">
                    <thead>
                        <tr>
                            <th colSpan="4">Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>isVisible</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Show and <strong>mounted</strong> the component in DOM.</td>
                        </tr>
                        <tr>
                            <td>seconds</td>
                            <td>number</td>
                            <td className="text-center">-</td>
                            <td>Give the time for countdown in second.</td>
                        </tr>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the title.</td>
                        </tr>
                        <tr>
                            <td>subText</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the subText.</td>
                        </tr>
                        <tr>
                            <td>buttons</td>
                            <td>array of JSX.Element</td>
                            <td className="text-center">-</td>
                            <td>Set buttons.</td>
                        </tr>
                        <tr>
                            <td>onTimeOut</td>
                            <td>() {'=>'} void</td>
                            <td className='text-center'>-</td>
                            <td>Callback to execute when the time out.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TimeOutMessageDoc;