import React from 'react';
import Highlight from 'react-highlight';
import { InfoCard } from '../../lib';


const infoCard = () => {
    return (
        <div>
            <h1 className="mb-3">InfoCard</h1>

            <h2 className="mb-2">Description</h2>

            <p className="mb-4">Information card which helps you list different information.</p>

            <div className="mb-2">
                <InfoCard
                    className="mb-3"
                    titles={['Requested Documents']}
                    description="You have 74 requested documents"
                    link={{ label: 'DETAILS', path: '#', isNativeLink: true, isOpenNewTab: true }} />

                <InfoCard
                    className="mb-3"
                    logo="https://i.ibb.co/mhGZ4kh/data-image-png-base.png"
                    titles={['Line of Credit - Man United']}
                    description="Unsecured loan application up to $100,000. No Collateral needed!"
                    link={{ label: 'APPLY NOW', path: '#' }} />

                <InfoCard
                    className="mb-3"
                    titles={['***-308', '***-310', '***-314']}
                    details={[
                        { title: 'Required for', text: 'TWO BUCKLESSxx' },
                        { title: 'Date invited', text: '15/10/2019' },
                        { title: 'Renewal Due By', text: '06/11/2020' },
                        { title: 'Status', text: 'Sent' },
                    ]}
                    button={{
                        label: 'START', onClicked: () => {
                            console.log('Clicked');
                        }
                    }} />
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { InfoCard } from 'vsg-design-system/InfoCard';

const Example = (props) => {
    return (
    <div>
        <InfoCard
            className="mb-3"
            titles={['Requested Documents']}
            description="You have 74 requested documents"
            link={{ label: 'DETAILS', path: '#', isNativeLink: true, isOpenNewTab: true }} />

        <InfoCard
            className="mb-3"
            logo="https://i.ibb.co/mhGZ4kh/data-image-png-base.png"
            titles={['Line of Credit - Man United']}
            description="Unsecured loan application up to $100,000. No Collateral needed!"
            link={{ label: 'APPLY NOW', path: '#' }} />

        <InfoCard
            className="mb-3"
            titles={['***-308', '***-310', '***-314']}
            details={[
                { title: 'Required for', text: 'TWO BUCKLESSxx' },
                { title: 'Date invited', text: '15/10/2019' },
                { title: 'Renewal Due By', text: '06/11/2020' },
                { title: 'Status', text: 'Sent' },
            ]}
            button={{
                label: 'START', onClicked: () => {
                    console.log('Clicked');
                }
            }} />
    </div>
    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>logo</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the logo.</td>
                    </tr>
                    <tr>
                        <td>titles</td>
                        <td>array of strings</td>
                        <td className="text-center">-</td>
                        <td>Sets the given texts as titles in the exact order.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the description text.</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>array of objects with keys: "title", "text"</td>
                        <td className="text-center">-</td>
                        <td>Set the details.</td>
                    </tr>
                    <tr>
                        <td>link</td>
                        <td>object with keys: "label", "path", "isNativeLink", "isOpenNewTab", "dataUI"</td>
                        <td className="text-center">-</td>
                        <td>Set the link.</td>
                    </tr>
                    <tr>
                        <td>button</td>
                        <td>object with keys: "label", "onClicked", "dataUI"</td>
                        <td className="text-center">-</td>
                        <td>Set the button.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default infoCard;