import React from 'react';
import { NavLink } from 'react-router-dom';
import { faMapPin, faPhone } from '@fortawesome/free-solid-svg-icons';

import {
    Button,
    NavSteps,
    Section,
    FormGroup,
    Select,
    MaskedInput,
    Input,
    ActionsWrapper,
    Row, 
    Col
} from '../../lib/index';

const pageFive = props => {
    const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    const usPhoneCodeMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    return (
        <div>
            <NavSteps className="mb-2" steps={[
                { text: 'Info', path: '/views/pages/1' },
                { text: 'Request', path: '/views/pages/2' },
                { text: 'Borrower', path: '/views/pages/3' },
                { text: 'Business References', path: '#' },
            ]} />
            <Section title="Accountant">
                <Row>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Accountant">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Email">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="City">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="3">
                        <FormGroup className="mb-2" label="State">
                            <Select options={[
                                { value: 'il', label: 'IL' },
                                { value: 'none', label: 'none' }
                            ]}>
                            </Select>
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="3">
                        <FormGroup className="mb-2" label="ZIP Code">
                            <MaskedInput
                                mask={zipCodeMask}
                                type='zipCode'
                                icon={faMapPin}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Phone">
                            <MaskedInput
                                type='phone'
                                mask={usPhoneCodeMask}
                                icon={faPhone}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Section>
            <Section title="Attorney">
                <Row>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Attorney">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Email">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="City">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="3">
                        <FormGroup className="mb-2" label="State">
                            <Select options={[
                                { value: 'ca', label: 'CA' },
                                { value: 'none', label: 'none' }
                            ]}>
                            </Select>
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="3">
                        <FormGroup className="mb-2" label="ZIP Code">
                            <MaskedInput
                                mask={zipCodeMask}
                                type='zipCode'
                                icon={faMapPin}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Phone">
                            <MaskedInput
                                type='phone'
                                mask={usPhoneCodeMask}
                                icon={faPhone}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Section>
            <Section title="Insurance Agent">
                <Row>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Insurance Agent">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Email">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="City">
                            <Input />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="3">
                        <FormGroup className="mb-2" label="State">
                            <Select options={[
                                { value: 'ny', label: 'NY' },
                                { value: 'none', label: 'none' }
                            ]}>
                            </Select>
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="3">
                        <FormGroup className="mb-2" label="ZIP Code">
                            <MaskedInput
                                mask={zipCodeMask}
                                type='zipCode'
                                icon={faMapPin}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <FormGroup className="mb-2" label="Phone">
                            <MaskedInput
                                type='phone'
                                mask={usPhoneCodeMask}
                                icon={faPhone}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Section>
            <Section>
                <p className="body-2 mb-2">
                    Click Continue to add Collateral to support your request.
                 </p>
                <ActionsWrapper className="mb-1">
                    <NavLink to={`/views/pages/6`}><Button>Continue</Button></NavLink>
                </ActionsWrapper>
            </Section>
        </div>
    );
}

export default pageFive;