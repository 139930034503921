import React from 'react';
import Highlight from 'react-highlight';
import { Section, Container } from '../../lib';



const containers = () => {
    return (
        <div>
            <h1 className='mb-3'>Containers</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Containers wrap your content and help to separate or align it.</p>

            <h3 className='mb-3'>Container</h3>
            <p className='mb-2'>
                Container component centers your content horizontally. It's the most basic layout element.
            </p>
            <div className='mb-2' style={{ backgroundColor: 'black' }}>
                <Container>
                    <p style={{ backgroundColor: '#e2f1f3', paddingTop: '40px', paddingBottom: '40px', height:'200px' }}></p>
                </Container>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Container } from 'vsg-design-system/Container';

const Example = (props) => {
    return (
        <Container>
            <p style={{ backgroundColor: '#e2f1f3', paddingTop: '40px', paddingBottom: '40px', height:'200px' }}></p>
        </Container>
    );
}

export default Example; 
`
                }
            </Highlight>

            <h3 className='mb-3'>Section</h3>
            <p className='mb-2'>
                Section component separates your content with horizontal line. Optionally you can add "link" and "title" to it.
            </p>
            <div className='mb-2'>
                <Section className="mb-2" title="Section with title and link" link={{ label: 'Link', path: '#' }}>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                     </p>
                </Section>
                <Section className="mb-2" title="Section with title">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                     </p>
                </Section>
                <Section className="mb-2" title="Section with title, header and footer" headerText="Section header text" footerText="Section footer text">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                     </p>
                </Section>
                <Section>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                     </p>
                </Section>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Section } from 'vsg-design-system/Section';

const Example = (props) => {
    return (
    <div>
        <Section title="Section with title and link" link={{ label: 'Link', path: '#' }}>
            Content....
        </Section>
        <Section title="Section with title">
            Content...
        </Section>
        <Section title="Section with title, header and footer" 
            headerText="Section header text" footerText="Section footer text">
            Content...
        </Section>
        <Section>
            Content...
        </Section>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title of the section.</td>
                    </tr>
                    <tr>
                        <td>link</td>
                        <td>objects with keys: "label", "path"</td>
                        <td className="text-center">-</td>
                        <td>Set link of the section.</td>
                    </tr>
                    <tr>
                        <td>headerText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set header title text of the section.</td>
                    </tr>
                    <tr>
                        <td>footerText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set footer title text of the section.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default containers;
