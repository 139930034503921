import React from 'react';
import Highlight from 'react-highlight';
import { Loader } from '../../lib';

const loader = () => {

    return <div>
        <h1 className='mb-3'>Loader</h1>

        <h2 className='mb-2'>Description</h2>
        <p className='mb-4'>Indicates the loading state of a component or page.</p>

        <div className='mb-2'>
            <Loader />
            <Loader isSmall />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Loader } from 'vsg-design-system/Loader';

const Example = (props) => {
    return (
    <div>
        <Loader />
        <Loader isSmall />
    </div>
    );
}
export default Example; 
    `}
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isSmall</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Set small loader.</td>
                </tr>
            </tbody>
        </table>

    </div>;
}

export default loader;
