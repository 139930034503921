import React from 'react';
import Highlight from 'react-highlight';
import { Button, ActionsWrapper} from '../../lib';



const actionsWrapper = () => {
    return (
        <div>
            <h1 className='mb-3'>Actions wrapper</h1>
            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>A wrapper that separates content and emphasises on key actions.</p>

            <div className='mb-2'>
                <ActionsWrapper>
                    <Button>Primary</Button>
                </ActionsWrapper>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Button} from 'vsg-design-system/Button';
import { ActionsWrapper } from 'vsg-design-system/ActionsWrapper';

const Example = (props) => {
    return (
    <div>
        <ActionsWrapper>
            <Button>Primary</Button>
        </ActionsWrapper>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>
        </div>
    )
}

export default actionsWrapper;