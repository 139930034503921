import React from 'react';

import {
    Section,
    InfoCard
} from '../../lib/index';

const renewalsPage = props => {
    return (
        <Section title="Loan Renewals">
            <p className="mb-3">Below are any loan renewals needing your attention.  Please start the invitation to begin the renewal process.</p>

            <InfoCard
                className="mb-3"
                titles={['*****6605']}
                details={[
                    { title: 'Required for', text: 'TWO BUCKLESSxx' },
                    { title: 'Date invited', text: '15/10/2019' },
                    { title: 'Renewal Due By', text: '06/11/2020' },
                    { title: 'Status', text: 'Sent' },
                ]}
                link={{ label: 'START' }} />

            <InfoCard
                className="mb-3"
                titles={['***-308', '***-310', '***-314']}
                details={[
                    { title: 'Required for', text: 'TWO BUCKLESSxx' },
                    { title: 'Date invited', text: '15/10/2019' },
                    { title: 'Renewal Due By', text: '06/11/2020' },
                    { title: 'Status', text: 'Sent' },
                ]}
                link={{ label: 'START' }} />

            <InfoCard
                className="mb-3"
                titles={['*****6605']}
                details={[
                    { title: 'Required for', text: 'TWO BUCKLESSxx' },
                    { title: 'Date invited', text: '15/10/2019' },
                    { title: 'Renewal Due By', text: '06/11/2020' },
                    { title: 'Status', text: 'Sent' },
                ]}
                link={{ label: 'START' }} />

            <InfoCard
                className="mb-3"
                titles={['*****6605']}
                details={[
                    { title: 'Required for', text: 'TWO BUCKLESSxx' },
                    { title: 'Date invited', text: '15/10/2019' },
                    { title: 'Renewal Due By', text: '06/11/2020' },
                    { title: 'Status', text: 'Sent' },
                ]}
                link={{ label: 'START' }} />

        </Section>
    );
}

export default renewalsPage;