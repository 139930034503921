import React from 'react';
import Highlight from 'react-highlight';
import { Alert } from '../../lib';

const alert = () => {

	return <div>
		<h1 className='mb-3'>Alert</h1>

		<h2 className='mb-2'>Description</h2>
		<p className='mb-4'>Provide contextual feedback messages for typical user actions with messages.</p>

		<h3 className='mb-3'>Alert types</h3>
		<p className='mb-2'>
			There are four type of alerts : "danger", "warning", "success", "info".
		</p>

		<div className='mb-2'>
			<Alert type="danger" text="Danger message here!" className="mb-2" />
			<Alert type="warning" text="Warning message here!" className="mb-2" />
			<Alert type="success" text="Success message here!" className="mb-2" />
			<Alert type="info" text="Info message here!" className="mb-2" />
		</div>

		<Highlight className="React mb-4">
			{
				`
import React from 'react';
import { Alert } from 'vsg-design-system/Alert';

const Example = (props) => {
    return (
    <div>
	<Alert type="danger" text="Danger message here!" />
	<Alert type="warning" text="Warning message here!" />
	<Alert type="success" text="Success message here!" />
	<Alert type="info" text="Info message here!" /> 
    </div>
    );
}

export default Example; 
`
			}
		</Highlight>

		<table className="doc-table mb-3">
			<thead>
				<tr>
					<th colSpan="4">Props</th>
				</tr>
				<tr>
					<td>
						<strong>Name</strong>
					</td>
					<td>
						<strong>Options</strong>
					</td>
					<td>
						<strong>Default</strong>
					</td>
					<td>
						<strong>Description</strong>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>type</td>
					<td>"danger", "warning", "success", "info"</td>
					<td className='text-center'>-</td>
					<td>Set different style to the alert. This prop is <strong>required</strong>.</td>
				</tr>
				<tr>
					<td>text</td>
					<td>string</td>
					<td className='text-center'>-</td>
					<td>Set alert message.</td>
				</tr>
			</tbody>
		</table>
	</div>;
}

export default alert;