import React from 'react';

import './docContainer.scss';

const docContainer = (props) => {
    return <div className="doc-container">
        {props.children}
    </div>;
}

export default docContainer;