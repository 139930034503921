import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import {
    Button,
    NavSteps,
    Section,
    PricingCard,
    ActionsWrapper,
    Checkbox,
    Row, 
    Col
} from '../../lib/index';

class PageEleven extends React.Component {

    constructor(props) {
        super();
        this.state = {
            selectedID: 1,
            allcardIds: [0, 1, 2]
        };
        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(id) {
        this.setState({
            selectedID: id
        });
    }

    render() {
        const titlesData =[
           {title:"3 Year Term", amount:"$100,000" },
           {title:"5 Year Term", amount:"$100,000"},
           {title:"Line of Credit" ,amount:"$100,000"}
        ];

        const conditionsData = [
            [
                { title: 'Monthly Payment', amount: '$3,151', description: ['(Principal & Interest)'] },
                { title: 'Interest Rate', amount: '8.25 %', description: ['(Fixed Rate)'] }
             ],
            [ 
                { title: 'Monthly Payment', amount: '$1,942', description: ['(Principal & Interest)'] },
                { title: 'Interest Rate', amount: '6.10 %', description: ['(Fixed Rate)'] }
            ],
            [
                { title: 'Monthly Payment', amount: 'Payment Amount Varies', description: ['(Interest Only)'] },
                { title: 'Interest Rate', amount: '9.25 %', description: ['(Variable Rate: WSJ Prime + 5.00%)'] }, 
            ]
        ];

        const feesData = [
            [{ title: 'Application Fee', amount: '$300' }],
            [{ title: 'Application Fee', amount: '$50' },{ title: 'Renewal Fee', amount: '$50' }],
            [{ title: 'Application Fee', amount: '$300' },{ title: 'Renewal Fee', amount: '$250' }]
        ];
 
        return (
            <div>
                <NavSteps className="mb-2" onLastStep={true} steps={[
                    { text: 'Info', path: '/views/pages/1' },
                    { text: 'Request', path: '/views/pages/2' },
                    { text: 'Borrower', path: '/views/pages/3' },
                    { text: 'Business References', path: '/views/pages/5' },
                    { text: 'Collateral', path: '/views/pages/6' },
                    { text: 'Debts / Deposits', path: '/views/pages/7' },
                    { text: 'Declarations', path: '/views/pages/8' },
                    { text: 'Wrap Up', path: '/views/pages/9' },
                    { text: 'Review Application', path: '/views/pages/10' },
                    { text: 'Results', path: '#' },
                ]} />
                <div className="mb-4">
                    <h1 className="text-center text-success mb-2" style={{ fontSize: '10rem' }}><FontAwesomeIcon icon={faThumbsUp} /></h1>
                    <h6 className="text-center text-success">Congratulations! You have been approved for a loan. </h6>
                    <p className="text-center">Please select one of the options below: </p>
                </div>
                <Row>
                    {
                        this.state.allcardIds.map((el, index) => {
                            return (
                                <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }} sm='12' key={index}>
                                    <PricingCard  
                                    onSelect={() => this.onSelect(index)} 
                                    title={titlesData[index]&&titlesData[index].title} 
                                    amount={titlesData[index]&&titlesData[index].amount} 
                                    feesData={feesData[index] ? feesData[index]:[]} 
                                    conditionsData={conditionsData[index] ? conditionsData[index]:[]} 
                                    selected={this.state.selectedID === el}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
                <Section>
                    <Checkbox className="mb-1" name="accept" value="1.9243">I Click this check box that you accept the loan terms. Repayment Terms: 60 Monthly principal and interest payment of $1.942.</Checkbox>
                    <p className="body-2 mb-2">
                        Loan amounts approved may differ from amount requested based on information provided. The application fee will be deducted from the loan proceeds. Annual renewal fees will be deducted from your Business checking account after 12 months and annually thereafter.You have 15 days to select a loan option from above. After 15 days, the application process will need to be restarted.lf you have any questions, please contact the Ovation Financial Contact Center at 555-555-5555.
                    </p>
                    <ActionsWrapper className="mb-1">
                        <NavLink to={`/views/pages/12`}><Button>Submit</Button></NavLink>
                    </ActionsWrapper>
                </Section>
            </div>
        )
    }
}

export default PageEleven;