import React from 'react';
import { NavLink } from 'react-router-dom';

import {
    Button,
    NavSteps,
    Section,
    FormGroup,
    Radio,
    ActionsWrapper,
    Row, 
    Col
} from '../../lib/index';

const pageFour = props => {
    return (
        <div>
            <NavSteps className="mb-2" steps={[
                { text: 'Info', path: '/views/pages/1' },
                { text: 'Request', path: '/views/pages/2' },
                { text: 'Borrower', path: '/views/pages/3' },
                { text: 'Identity', path: '#' },
            ]} />
            <p className="mb-2">To continue with the application, questions based on your personal information will be asked to verify your identity. This does not pull your credit report.</p>
            <Section title="Identity Verification Questions">
                <Row>
                    <Col sm="12">
                        <h6 className="mb-1">You may have opened an auto loan or auto lease in or around August 2017. Please select the dollar amount range in which your monthly auto loan or lease payment falls. If you have not had an auto loan or lease with any of these amount ranges now or in the past, please select 'None of the above/does not apply'.</h6>
                        <FormGroup className="mb-2" >
                            <Radio name="loan" value="4">$450 - $549</Radio>
                            <Radio name="loan" value="5">$550 - $649</Radio>
                            <Radio name="loan" value="6">$650 - $749</Radio>
                            <Radio name="loan" value="7">$750 - $849 </Radio>
                            <Radio name="loan" value="no">None of the above/does not apply</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <h6 className="mb-1">According to your credit profile, you may have opened an auto loan in or around August 2017. Please select the lender for this account. If you do not have such an auto loan, select 'None of the above/does not apply'.</h6>
                        <FormGroup className="mb-2">
                            <Radio name="credit" value="B">Bank one</Radio>
                            <Radio name="credit" value="C">Chase manhattan</Radio>
                            <Radio name="credit" value="B">Volvo fin</Radio>
                            <Radio name="credit" value="CH">Chrysler credit</Radio>
                            <Radio name="credit" value="no">None of the above/does not apply</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <h6 className="mb-1">Which of the following is a current or previous employer? If there is not a matched employer name, please select 'NONE OF THE ABOVE'.</h6>
                        <FormGroup className="mb-2">
                            <Radio name="employer" value="R">Robertshom</Radio>
                            <Radio name="employer" value="P">Post office</Radio>
                            <Radio name="employer" value="V">Viacom</Radio>
                            <Radio name="employer" value="N">Newcor</Radio>
                            <Radio name="employer" value="no">None of the above/does not apply</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <h6 className="mb-1">According to your credit profile, you may have opened a mortgage loan in or around October 2016. Please select the lender to whom you currently make your mortgage payments. If you do not have a mortgage, select 'None of the above/does not apply'. US</h6>
                        <FormGroup className="mb-2">
                            <Radio name="mortgage" value="M">Mortgage exchange</Radio>
                            <Radio name="mortgage" value="C">Citicorp mort</Radio>
                            <Radio name="mortgage" value="F">Firstmerit bank</Radio>
                            <Radio name="mortgage" value="C">Chase home mtg</Radio>
                            <Radio name="mortgage" value="no">None of the above/does not apply</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <h6 className="mb-1">According to your credit profile, you may have opened a (CAPITAL ONE) credit card. Please select the year in which your account was opened.</h6>
                        <FormGroup className="mb-2">
                            <Radio name="year" value="2012">2012</Radio>
                            <Radio name="year" value="2014">2014</Radio>
                            <Radio name="year" value="2016">2016</Radio>
                            <Radio name="year" value="2018">2018</Radio>
                            <Radio name="year" value="no">None of the above/does not apply</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <h6 className='mb-1'>Using today's date to determine your current age, how old will you be in exactly 5 years?</h6>
                        <FormGroup className="mb-2">
                            <Radio name="age" value="58">58</Radio>
                            <Radio name="age" value="60">60</Radio>
                            <Radio name="age" value="62">62</Radio>
                            <Radio name="age" value="64">64</Radio>
                            <Radio name="age" value="no">None of the above/does not apply</Radio>
                        </FormGroup>
                    </Col>
                </Row>
            </Section>
            <Section>
                <p className="body-2 mb-2">
                    Upon answering these questions, if any questions are incorrect. you will be given an additional opportunity to answer new questions to complete identity verification. If your identity is not verified. an instant decision on your application may not be available.
                 </p>
                <ActionsWrapper className="mb-1">
                    <NavLink to={`/views/pages/5`}><Button>Submit</Button></NavLink>
                </ActionsWrapper>
            </Section>
        </div>
    );
}

export default pageFour;