import React from 'react';
import { Link } from 'react-router-dom';

import Highlight from 'react-highlight';

const header = () => {
    return (
        <div>
            <h1 className='mb-3'>Header</h1>
            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Header is navigation components that displays information and actions relating to the current screen.</p>

            <div className='mb-2'>
                <h5><Link to="/header-demo-page">Header Demo Page</Link></h5>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React, { Component } from 'react';
import { Header } from 'vsg-design-system/Header';
import { NavMenu } from 'vsg-design-system/NavMenu';

class Example extends Component {

    state = {
        visible: false
    }

    handleOpenMenu = () => {
        this.setState({ visible: true });
    }

    handleCloseMenu = () => {
        this.setState({ visible: false });
    }

    render() {

        const navMenu = <NavMenu .... />;

        return (
            <Header
                className="mb-3"
                left={{
                    logo: 'https://pik-oplenac.com/wp-content/plugins/allsecure_woo/assets/images/light/unicredit.svg'
                }}
                right={{
                    topText: '0800 800 800',
                    midText: 'Custom Client Header',
                    bottomText: 'Small text here'
                }}
                navMenu={navMenu}
                isMenuVisible={this.state.visible}
                onMenuOpen={this.handleOpenMenu}
                onMenuClose={this.handleCloseMenu}
            />
        );
    }
}

export default Example; 
    `
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>left</td>
                        <td>object with key: "logo" <strong>or</strong> <br /> object with key: "title"</td>
                        <td className="text-center">-</td>
                        <td>Set content for left side.</td>
                    </tr>
                    <tr>
                        <td>right</td>
                        <td>object with key: "logo" <strong>or</strong> <br /> object with keys: "topText", "midText", "bottomText"</td>
                        <td className="text-center">-</td>
                        <td>Set content for right side.</td>
                    </tr>
                    <tr>
                        <td>links</td>
                        <td>array of objects with keys: "text", "path", "onClick"</td>
                        <td className="text-center">-</td>
                        <td>Set action links under the header.</td>
                    </tr>
                    <tr>
                        <td>navMenu</td>
                        <td><Link to="/docs/navMenu">JSX.Element</Link></td>
                        <td className="text-center">-</td>
                        <td>Set the navMenu.</td>
                    </tr>
                    <tr>
                        <td>isMenuVisible</td>
                        <td>boolen</td>
                        <td>false</td>
                        <td>Set navMenu visibility.</td>
                    </tr>
                    <tr>
                        <td>onMenuOpen</td>
                        <td>function</td>
                        <td className="text-center">-</td>
                        <td>Callback to execute when the navMenu is open.</td>
                    </tr>
                    <tr>
                        <td>onMenuClose</td>
                        <td>function</td>
                        <td className="text-center">-</td>
                        <td>Callback to execute when the navMenu is closed.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default header;