import React from 'react';
import Highlight from 'react-highlight';

import { Banner, Row, Col } from '../../lib';

import bannerImg from '../../views/img/banner.jpeg';
import bannerTablet from '../../views/img/bannerTablet.png';

const banner = () => {
    return (
        <div>
            <h1 className="mb-3">Banner</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-4">Banner component helps you to display images in different sizes - for desktop, tablet and mobile.</p>

            <div className="mb-2">
                <Row>
                    <Col lg="3" md={{ size: 12, offset: 0 }} sm={{ size: 10, offset: 1 }} className="mb-3">
                        <Banner
                            desktopImg={bannerImg}
                            tabletImg={bannerTablet}
                            mobileImg={bannerImg} />
                    </Col>
                </Row>
            </div>
            <Highlight className="React mb-4">
                {`
import React from 'react';
import { Banner } from 'vsg-design-system/Banner';
import { Row } from 'vsg-design-system/Row';
import { Col } from 'vsg-design-system/Col';

import bannerImg from '../../views/img/banner.jpeg';
import bannerTablet from '../../views/img/bannerTablet.png';

const Example = (props) => {
    return (
        // Row and Col is for demo purpose
        <Row>
            <Col lg="3" md={{ size: 12, offset: 0 }} sm={{ size: 10, offset: 1 }} className="mb-3">
                <Banner
                    desktopImg={bannerImg}
                    tabletImg={bannerTablet}
                    mobileImg={bannerImg} />
            </Col>
        </Row>
    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>desktopImg</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the image for desktop size.</td>
                    </tr>
                    <tr>
                        <td>tabletImg</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the image for tablet size. If is empty takes the image from 'desktopImg'.</td>
                    </tr>
                    <tr>
                        <td>mobileImg</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the image for mobile size. If is empty takes the image from 'tabletImg'.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default banner;