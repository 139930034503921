import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
    Header,
    NavMenu,
    ReactAux,
    Container
} from '../../lib/index';

class HeaderDemoPage extends Component {

    state = {
        visible: false
    }

    handleOpenMenu = () => {
        this.setState({ visible: true });
    }

    handleCloseMenu = () => {
        this.setState({ visible: false });
    }

    render() {

        const navMenu = <NavMenu
            user="webuser35@mailinator.com"
            changePassword={{ path: '#', isNativeLink: true }}
            signOut={{
                onSignOut: () => {
                    console.log('Sign Out');
                }
            }}
            onLinkClick={this.handleCloseMenu}
            sections={[
                {
                    links: [
                        { title: 'HOME', path: '#', isActive: true }
                    ]
                },
                {
                    title: 'Requested Documents',
                    links: [
                        { title: 'REQUESTED', path: '#', number: 6, isActive: false },
                        { title: 'HISTORY', path: '#' },
                    ]
                },
                {
                    title: 'Loan Applications',
                    links: [
                        { title: 'NEW REQUEST', path: '#' },
                        { title: 'RENEWALS', path: '#', number: 14 },
                        { title: 'HISTORY', path: '#' },
                    ]
                }
            ]} />;

        return (
            <ReactAux>
                <Header
                    className="mb-3"
                    left={{
                        logo: 'https://pik-oplenac.com/wp-content/plugins/allsecure_woo/assets/images/light/unicredit.svg'
                    }}
                    right={{
                        topText: '0800 800 800',
                        midText: 'Custom Client Header',
                        bottomText: 'Small text here'
                    }}
                    navMenu={navMenu}
                    isMenuVisible={this.state.visible}
                    onMenuOpen={this.handleOpenMenu}
                    onMenuClose={this.handleCloseMenu}
                />

                <Container>
                    <h6 className="mb-3"><i>Resize to mobile to see NavMenu.</i></h6>
                    <h5><Link to="/docs/header">Back To Docs</Link></h5>
                </Container>
            </ReactAux>
        );
    }
}

export default HeaderDemoPage;