import React from 'react';
import { Link } from 'react-router-dom';

import {
    Section,
} from '../../lib/index';

const loanHistoryPage = props => {
    return (
        <Section title="Loan Application History">
            <p className="mb-3">All loan applications started online will be shown below. Select the application number for applications with a pending status to continue and complete the process. The Application Number link will provide the result of the applications that are completed.</p>
            <div className="history-table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th><p className="body2 text-nowrap"><strong>Application #:</strong></p></th>
                            <th><p className="body2"><strong>Created:</strong></p></th>
                            <th><p className="body2"><strong>Submited:</strong></p></th>
                            <th><p className="body2"><strong>Status:</strong></p></th>
                            <th><p className="body2"><strong>Borrower:</strong></p></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                        <tr>
                            <td><p className="body2 text-primary"><Link to="#">007CM</Link></p></td>
                            <td><p className="body2">11/28/2019</p></td>
                            <td><p className="body2">11/29/2019:</p></td>
                            <td><p className="body2">On Hold</p></td>
                            <td><p className="body2">TWO BUCKLESSxx</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Section>
    );
}

export default loanHistoryPage;