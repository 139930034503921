import React from 'react';

import {
    Section,
    InfoCard
} from '../../lib/index';

const newRequestedPage = props => {
    return (
        <Section title="Finance Opportunities">
            <p className="mb-3">Below are some finance opportunities you may apply for.  Each one has additional details and some may provide an immediate decision.</p>

            <InfoCard
                className="mb-3"
                logo="https://pik-oplenac.com/wp-content/plugins/allsecure_woo/assets/images/light/unicredit.svg"
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW' }} />

            <InfoCard
                className="mb-3"
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW' }} />

            <InfoCard
                className="mb-3"
                logo="https://i.ibb.co/mhGZ4kh/data-image-png-base.png"
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW' }} />

            <InfoCard
                className="mb-3"
                logo="https://image4.owler.com/logo/people-s-united-bank_owler_20190716_074040_original.png"
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW' }} />

            <InfoCard
                className="mb-3"
                logo="https://i.ibb.co/Zh0HMLB/data-image-png-base-1.png"
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW' }} />

        </Section>
    );
}

export default newRequestedPage;