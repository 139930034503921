import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import Docs from './docs/Docs';
import App from './App';
import DemoPages from './views/DemoPages';
import ClientPortal from './views/ClientPortal';
import HeaderDemoPage from './docs/sections/HeaderDemoPage'

const IndexContainer = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Redirect exact from="/" to="/docs/introduction" />
                <Redirect exact from="/docs" to="/docs/introduction" />
                <Route path="/docs" component={Docs} />
                <Route path="/components" exact render={props => <App {...props} />} />
                <Route path="/views" render={props => <DemoPages {...props} />} />
                <Route path="/client-portal" render={props => <ClientPortal {...props} />} />
                <Route path="/header-demo-page" render={props => <HeaderDemoPage {...props} />} />
            </Switch>
        </BrowserRouter>
    )
};

ReactDOM.render(<IndexContainer />, document.getElementById('root'));
