import React from 'react';
import Highlight from 'react-highlight';
import { PricingCard, Row, Col } from '../../lib';



const pricingCard = () => {
    const conditionsData = [
        { title: 'Monthly Payment', amount: '$3,151', description: ['(Principal & Interest)', <strong>(Second description)</strong>] },
        { title: 'Interest Rate', amount: '6.10%', description: ['(Fixed Rate)'] }
    ];
    const conditionsDataSecond = [
        { title: 'Monthly Payment', amount: '$3,151', description: ['(Principal & Interest)'] },
        { title: 'Interest Rate', amount: '6.10%', description: ['(Fixed Rate)', '(fix rate)'] },
        { title: 'Interest Rate', amount: '16.10%', description: ['(Fixed Rate)' ]},
    ];
    const feesData = [
        { title: 'Application Fee', amount: '$300' },
        { title: 'Renewal Fee', amount: '$50' }
    ];
    return (
        <div>
            <h1 className='mb-3'>Pricing Card</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The QD Design System's custom Pricing Card components should be used to display pricing options, usually at the
                end of the user's application.</p>

            <h3 className='mb-3'>Pricing Card states</h3>

            <div className='mb-2'>
                <Row>
                    <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }} sm='12'>
                        <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsDataSecond}/>
                    </Col>
                    <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }} sm='12'>
                        <PricingCard title="5 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} selected={true} />
                    </Col>
                    <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }} sm='12'>
                        <PricingCard title="Line of Credit" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} />
                    </Col>
                </Row>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Row } from 'vsg-design-system/Row';
import { Col } from 'vsg-design-system/Col';
import { PricingCard } from 'vsg-design-system/PricingCard';

const Example = (props) => {
    // Demo data
    const conditionsData = [
        { title: 'Monthly Payment', amount: '$3,151', description: ['(Principal & Interest)',<strong>(Second description)</strong>] },
        { title: 'Interest Rate', amount: '6.10%', description: ['(Fixed Rate)'] }
    ];
    const conditionsDataSecond = [
        { title: 'Monthly Payment', amount: '$3,151', description: ['(Principal & Interest)'] },
        { title: 'Interest Rate', amount: '6.10%', description: ['(Fixed Rate)'] },
        { title: 'Interest Rate', amount: '16.10%', description: ['(Fixed Rate)'] },
    ];
    const feesData = [
        { title: 'Application Fee', amount: '$300' },
        { title: 'Renewal Fee', amount: '$50' }
    ];
    return (
    <div>
        <Row>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }} sm='12'>
                <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsDataSecond} />
            </Col>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }} sm='12'>
                <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} selected={true} />
            </Col>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }} sm='12'>
                <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} />
            </Col>
         </Row>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>selected</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set selected style to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>amount</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set amount to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>conditionsData</td>
                        <td>array of objects with keys: 
                            "title": string, <br/>
                            "amount": string, <br/>
                            "description": Array of strings || JSX.Element
                        </td>
                        <td className="text-center">-</td>
                        <td>Set conditions to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>feesData</td>
                        <td>array of objects with keys: "title", "amount"</td>
                        <td className="text-center">-</td>
                        <td>Set fees to the pricing card.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default pricingCard;