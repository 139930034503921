import React, { Component } from 'react';
import Highlight from 'react-highlight';
import { FileUploadCard } from '../../lib';

class FileUploadCardDocs extends Component {

    onSubmitFile = (i) => {
        const fileRef = this[`fileRef${i}`];

        // Get the file
        console.log(fileRef.state.file);

        // Randomly return Success or Error for demo purpose.
        if (Math.floor(Math.random() * 11) % 2 === 0) {

            // If success call this function
            fileRef.onSuccess();

        } else {

            // If error call this function
            fileRef.onError('Error Message')

        }
    }

    render() {
        return <div>
            <h1 className="mb-3">FileUploadCard</h1>

            <h2 className="mb-2">Description</h2>
            <p className="mb-4">Custom FileUploadCard component, enables to file upload through browsing or drag and drop.
                <br /> The example returns <strong>random</strong> success or error.
            </p>

            <div className="mb-2">
                <FileUploadCard
                    className="mb-4"
                    title="Recurring document title goes here"
                    maxFileSizeMB={0.2}
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    onSubmit={() => this.onSubmitFile(0)}
                    onRef={ref => (this[`fileRef${0}`] = ref)}
                />

                <FileUploadCard
                    className="mb-4"
                    title="LV document"
                    maxFileSizeMB={2}
                    details={[
                        { title: 'Required for:', text: 'FMDEF LS TEST CZAR' },
                        { title: 'Description:', text: 'This pre. doc will be sent to the Portal if the business party = TRUE.' },
                    ]}
                    onSubmit={() => this.onSubmitFile(1)}
                    onRef={ref => (this[`fileRef${1}`] = ref)}
                />
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React, { Component } from 'react';
import { FileUploadCard } from 'vsg-design-system/FileUploadCard';

class Example extends Component {

    onSubmitFile = (i) => {
        const fileRef = this[\`fileRef\${i}\`];

        // Get the file
        console.log(fileRef.state.file);

        // Randomly return Success or Error for demo purpose.
        if (Math.floor(Math.random() * 11) % 2 === 0) {

            // If success call this function
            fileRef.onSuccess();

        } else {

            // If error call this function
            fileRef.onError('Error Message');

        }
    }

    render() {

        return (
            <div>
                <FileUploadCard
                    className="mb-4"
                    title="Recurring document title goes here"
                    maxFileSizeMB={0.2}
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    onSubmit={() => this.onSubmitFile(0)}
                    onRef={ref => (this[\`fileRef\${0}\`] = ref)}
                />

                <FileUploadCard
                    className="mb-4"
                    title="LV document"
                    maxFileSizeMB={2}
                    details={[
                        { title: 'Required for:', text: 'FMDEF LS TEST CZAR' },
                        { title: 'Description:', text: 'This pre. doc will be sent to the Portal if the business party = TRUE.' },
                    ]}
                    onSubmit={() => this.onSubmitFile(1)}
                    onRef={ref => (this[\`fileRef\${1}\`] = ref)}
                />
            </div>
        );
    }
}
export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title to the card.</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>array of objects with keys: "text", "path"</td>
                        <td className="text-center">-</td>
                        <td>Set details to the card.</td>
                    </tr>
                    <tr>
                        <td>maxFileSizeMB</td>
                        <td>number</td>
                        <td>50</td>
                        <td>Set maximum file size.</td>
                    </tr>
                    <tr>
                        <td>onSubmit</td>
                        <td>function</td>
                        <td className="text-center">-</td>
                        <td>Callback for when the "submit" button is clicked.</td>
                    </tr>
                    <tr>
                        <td>onRef</td>
                        <td>function</td>
                        <td className="text-center">-</td>
                        <td>Reference to 'this' FileUploadCard.</td>
                    </tr>
                </tbody>
            </table>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Ref Methods</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Props</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>onSuccess</td>
                        <td></td>
                        <td>Callback for success. Set style on success.</td>
                    </tr>
                    <tr>
                        <td>onError</td>
                        <td>errorMessage: string</td>
                        <td>Callback for error. Set style on error.</td>
                    </tr>
                </tbody>
            </table>

        </div>;
    }
}

export default FileUploadCardDocs;
