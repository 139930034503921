import React from 'react';
import Highlight from 'react-highlight';
import { Tooltip } from '../../lib';


const tooltip = () => {

    return <div>
        <h1 className='mb-3'>Tooltip</h1>

        <h2 className='mb-2'>Description</h2>
        <p className='mb-4'>
            Tooltips are small, interactive, textual hints.
            <br /> QD Design System Tooltip components use "react-tooltip" library.
            Check demo <a href="https://react-tooltip.netlify.com/" target="_blank" rel="noopener noreferrer">here</a> and documentation <a href="https://github.com/wwayne/react-tooltip#react-tooltip" target="_blank" rel="noopener noreferrer">here</a>.
        </p>

        <div className='mb-2'>
            <p className="d-inline-block mr-3" data-tip="Info text here" data-place="right">Tooltip right</p>
            <p className="d-inline-block mr-3" data-tip="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">Tooltip top</p>
            <p className="d-inline-block mr-3" data-tip="Tooltip" data-place="bottom">Tooltip bottom</p>
            <p className="d-inline-block mr-3" data-tip="Info text here" data-place="left">Tooltip left</p>

            <Tooltip place="top" effect="solid" />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Tooltip } from 'vsg-design-system/Tooltip';

const Example = (props) => {
    return (
    <div>
        <p data-tip="Info text here" data-place="right">Tooltip right</p>
        <p data-tip="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">Tooltip top</p>
        <p data-tip="Tooltip" data-place="bottom">Tooltip bottom</p>
        <p data-tip="Info text here" data-place="left">Tooltip left</p>

        // Place the tooltip one time at the root of app is the best place.
        <Tooltip place="top" effect="solid" />
    );
}
export default Example; 
    `}
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>all options from lib</td>
                    <td className="text-center">-</td>
                    <td className="text-center">-</td>
                    <td><a href="https://github.com/wwayne/react-tooltip#options" target="_blank" rel="noopener noreferrer">Options list.</a></td>
                </tr>
            </tbody>
        </table>

    </div>;
}

export default tooltip;