import React, { Component } from 'react';

import {
    Section,
    FileUploadCard
} from '../../lib/index';

class RequestedPage extends Component {

    onSubmitFile = (i) => {
        const fileRef = this[`fileRef${i}`];

        fileRef.onSuccess();
    }

    render() {
        return (
            <Section title="Requested Documents">
                <p className="mb-3">The below documents have been requested to support a new or renewing loan or for ongoing needs.  The document title describes the document needed and which entity the document is required for.  If stated, the statement date or description will identity the statement period needed.  If a due date is listed, please attempt to provide the document by this date.  If the document cannot be returned by the due date, please contact us.</p>
                <FileUploadCard
                    className="mb-3"
                    title="Recurring document title goes here"
                    maxFileSizeMB={0.2}
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    onSubmit={() => this.onSubmitFile(0)}
                    onRef={ref => (this[`fileRef${0}`] = ref)}
                />
                <FileUploadCard
                    className="mb-3"
                    title="Recurring document title goes here"
                    maxFileSizeMB={2}
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    onSubmit={() => this.onSubmitFile(1)}
                    onRef={ref => (this[`fileRef${1}`] = ref)}
                />
                <FileUploadCard
                    className="mb-3"
                    title="Recurring document title goes here"
                    maxFileSizeMB={5}
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    onSubmit={() => this.onSubmitFile(2)}
                    onRef={ref => (this[`fileRef${2}`] = ref)}
                />
            </Section>
        );
    }
}

export default RequestedPage;