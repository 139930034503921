import React from 'react';
import { Link } from 'react-router-dom';

import { classNameHelper } from '../../../lib/utilities/component-helpers';

const linkItem = (props) => {

    const locationPath = window.location.pathname;

    let isActive = null;

    const links = props.links.map((l, i) => {

        return <li key={i} className={locationPath === l.path ? isActive = 'active' : null}>
            <Link to={l.path}>{l.title}</Link>
        </li>
    });

    return <div className={classNameHelper('link-item', isActive)}>
        <Link to={props.links[0].path} className="link">{props.title}</Link>
        <ul className="sidenav">
            {links}
        </ul>
    </div>
}

export default linkItem;