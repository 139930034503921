import React from 'react';
import { NavLink } from 'react-router-dom';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  NavSteps,
  Section,
  FormGroup,
  Select,
  MaskedInput,
  Textarea,
  Radio,
  Input,
  ActionsWrapper,
  ValidationMessage,
  Toast,
  Row, 
  Col
} from '../../lib/index';



const pageTwo = props => {
    
const notifyDanger = () => Toast.danger('Danger message here!');
notifyDanger();

  return (
    <div>
      <NavSteps className="mb-2" steps={[
        { text: 'Info', path: '/views/pages/1' },
        { text: 'Request', path: '#' },
      ]} />
      <Section title="Purpose and Product">
        <Row>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Purpose of Loan" hoverHelp="Lorem Ipsum is simply dummy text">
              <Select options={[
                { value: 'workingCapital', label: 'Working Capital' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Product Type">
              <Select className='invalid' options={[
                { value: 'creditCard', label: 'Credit Card' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
              <ValidationMessage message="Invalid field" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-2" label="Amount Requested">
              <MaskedInput className='invalid' type="number" icon={faDollarSign} />
              <ValidationMessage message="Invalid field" />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup className="mb-2" label="Specific Reason">
              <Textarea  rows="5" className='invalid'></Textarea>
              <ValidationMessage message="Invalid field" />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" label="Location of Business">
              <Select options={[
                { value: 'ak', label: 'AK' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" label="County of Business">
              <Select  options={[
                { value: 'ak', label: 'Aleutians West Census Area' },
                { value: 'none', label: 'none' }
              ]}>
                <ValidationMessage message="Invalid field" />
              </Select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" label="Existing Deposit Customer?">
              <Radio className="d-inline-block mr-1" name="deposit" value="yes">Yes</Radio>
              <Radio className="d-inline-block" name="deposit" value="no">No</Radio>
              <ValidationMessage message="Invalid field" />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <h6 className="mb-1">Date Business Started</h6>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" label="Month">
              <Select  options={[
                { value: 'ap', label: 'April' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" label="Year">
              <Input type="text" className='invalid'/>
              <ValidationMessage message="Invalid field" />
            </FormGroup>
          </Col>
        </Row>
      </Section>
      <Section>
        <p className="body-2 mb-2">
          Owner and Business information will be requested on the next page.
            </p>
        <ActionsWrapper className="mb-1">
          <NavLink to={`/views/pages/3`}><Button>Continue</Button></NavLink>
        </ActionsWrapper>
      </Section>
    </div>
  );
}

export default pageTwo;