import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    ReactAux,
    Header,
    NavMenu,
    Container,
    Row,
    Col,
    Footer,
    Banner
} from '../lib/index';

import HomePage from './ClientPortalPages/HomePage';
import RequestedPage from './ClientPortalPages/RequestedPage';
import RequestedHistoryPage from './ClientPortalPages/RequestedHistoryPage';
import NewRequestedPage from './ClientPortalPages/NewRequestedPage';
import RenewalsPage from './ClientPortalPages/RenewalsPage';
import LoanHistoryPage from './ClientPortalPages/LoanHistoryPage';

import banner from './img/banner.jpeg';
import bannerTablet from './img/bannerTablet.png';


class ClientPortal extends Component {

    state = {
        visible: false
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    handleOpenMenu = () => {
        this.setState({ visible: true });
    }

    handleCloseMenu = () => {
        this.setState({ visible: false });
    }

    render() {

        const currentPathname = this.props.location.pathname;

        const navMenu = <NavMenu
            user="webuser35@mailinator.com"
            changePassword={{ path: '#', isNativeLink: true }}
            signOut={{
                onSignOut: () => {
                    console.log('Sign Out');
                }
            }}
            onLinkClick={this.handleCloseMenu}
            sections={[
                {
                    links: [
                        { title: 'HOME', path: '/client-portal', isActive: currentPathname === '/client-portal' }
                    ]
                },
                {
                    title: 'Requested Documents',
                    links: [
                        { title: 'REQUESTED', path: '/client-portal/requested-page', number: 6, isActive: currentPathname === '/client-portal/requested-page' },
                        { title: 'HISTORY', path: '/client-portal/requested-history-page', isActive: currentPathname === '/client-portal/requested-history-page' },
                    ]
                },
                {
                    title: 'Loan Applications',
                    links: [
                        { title: 'NEW REQUEST', path: '/client-portal/new-requested-page', isActive: currentPathname === '/client-portal/new-requested-page' },
                        { title: 'RENEWALS', path: '/client-portal/renewals-page', number: 14, isActive: currentPathname === '/client-portal/renewals-page' },
                        { title: 'HISTORY', path: '/client-portal/loan-history-page', isActive: currentPathname === '/client-portal/loan-history-page' },
                    ]
                }
            ]} />;

        return (
            <ReactAux>
                <Header
                    className="mb-3"
                    left={{
                        logo: 'https://pik-oplenac.com/wp-content/plugins/allsecure_woo/assets/images/light/unicredit.svg'
                    }}
                    right={{
                        topText: '0800 800 800',
                        midText: 'Custom Client Header',
                        bottomText: 'Small text here'
                    }}
                    navMenu={navMenu}
                    isMenuVisible={this.state.visible}
                    onMenuOpen={this.handleOpenMenu}
                    onMenuClose={this.handleCloseMenu}
                />

                <Container>
                    <Row>
                        <Col lg="3" md="4" className="d-none d-md-block mb-3">
                            {navMenu}
                        </Col>
                        <Col lg="6" md="8" className="mb-3">
                            <Switch>
                                <Route path="/client-portal/requested-page" component={RequestedPage} />
                                <Route path="/client-portal/requested-history-page" component={RequestedHistoryPage} />
                                <Route path="/client-portal/new-requested-page" component={NewRequestedPage} />
                                <Route path="/client-portal/renewals-page" component={RenewalsPage} />
                                <Route path="/client-portal/loan-history-page" component={LoanHistoryPage} />
                                <Route path="/client-portal" component={HomePage} />
                            </Switch>
                        </Col>
                        <Col lg="3" md={{ size: 12, offset: 0 }} sm={{ size: 10, offset: 1 }} className="mb-3">
                            <Banner
                                desktopImg={banner}
                                tabletImg={bannerTablet}
                                mobileImg={banner} />
                        </Col>
                    </Row>
                    <Footer className="text-center" text="© 2019 Jack Henry & Associates - 3.19.20.35" />
                </Container>
            </ReactAux>
        );
    }
}

export default ClientPortal;