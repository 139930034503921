import React from 'react';
import Highlight from 'react-highlight';
import { Button } from '../../lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const button = () => {
    return (
        <div>
            <h1 className='mb-3'>Button</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Button components in QD Design System extend the native Button element. Use QD Design System's custom button styles for actions in forms, dialogs, and more.</p>

            <h3 className='mb-3'>Button types</h3>
            <p className='mb-2'>
                There are tree type of buttons : "primary", "secondary", "icon".
            </p>
            <div className='mb-2'>
                <Button className="mb-2 mr-2" btnType="primary">Primary</Button>
                <Button className="mb-2 mr-2" btnType="secondary">Secondary</Button>
                <Button className='mb-2 mr-2' btnType="icon"><FontAwesomeIcon icon={faTrash} /></Button>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Button } from 'vsg-design-system/Button';
import { FontAwesomeIcon } from 'vsg-design-system/FontAwesomeIcon';
import { IconsSolid } from 'vsg-design-system/IconsSolid';

const Example = (props) => {
    return (
    <div>
        <Button btnType="primary">primary</Button>
        <Button btnType="secondary">secondary</Button>
        <Button btnType="icon"><FontAwesomeIcon icon={IconsSolid.faTrash} /></Button>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>
            
            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>btnType</td>
                        <td>"primary", "secondary", "icon"</td>
                        <td>"primary"</td>
                        <td>Set different style to the button.</td>
                    </tr>
                    <tr>
                        <td>all native button attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native button attributes like: "type", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

            <h3 className='mb-3'>Disabled Button</h3>
            <div className='mb-2'>
                <Button className="mb-2 mr-2" btnType="primary" disabled>Primary</Button>
                <Button className="mb-2 mr-2" btnType="secondary" disabled>Secondary</Button>
                <Button className='mb-2 mr-2' btnType="icon" disabled><FontAwesomeIcon icon={faTrash} /></Button>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Button } from 'vsg-design-system/Button';
import { FontAwesomeIcon } from 'vsg-design-system/FontAwesomeIcon';
import { IconsSolid } from 'vsg-design-system/IconsSolid';

const Example = (props) => {
    return (
    <div>
        <Button btnType="primary" disabled >Primary</Button>
        <Button btnType="secondary" disabled >Secondary</Button>
        <Button btnType="icon" disabled ><FontAwesomeIcon icon={IconsSolid.faTrash} /></Button>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>
        </div>
    )
}

export default button;