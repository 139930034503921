import React from 'react';
import Highlight from 'react-highlight';
import { Footer } from '../../lib';



const footer = () => {
    return (
        <div>
            <h1 className='mb-3'>Footer</h1>
            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The layout ending component, according to the QD Design System principles.</p>

            <div className='mb-2'>
                <Footer text="© Copyright or other text" />
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Footer } from 'vsg-design-system/Footer';

const Example = (props) => {
    return (
    <div>
        <Footer text="© Copyright or other text" />
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan="4">Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>text</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set footer text</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default footer;