import React from 'react';
import { NavLink } from 'react-router-dom';
import { faDollarSign, faMapPin } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  NavSteps,
  Section,
  FormGroup,
  Select,
  MaskedInput,
  Textarea,
  Radio,
  Input,
  ActionsWrapper,
  FormSection,
  ConfirmDeleteButton,
  AddFormSectionButton,
  Row, 
  Col
} from '../../lib/index';


class PageSix extends React.Component {
  
  constructor(props) {
    super();
    this.state = {
      allSections: [this.newSectionLoan]
    };

    this.addNewLoan = this.addNewLoan.bind(this);
    this.printLoans = this.printLoans.bind(this);
  }

  newSectionLoan(index) {
    const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    return (
      <FormSection className='mb-2' key={index}>
      <h6 className="mb-2">{index+1}.Collateral to Secure Your Business Loan</h6>
      <Row>
        <Col sm="12" md="6">
          <FormGroup className="mb-2" label="Category">
            <Select options={[
              { value: 'ap', label: 'EQUIPMENT' },
              { value: 'none', label: 'none' }
            ]}>
            </Select>
          </FormGroup>
        </Col>
        <Col sm="12" md="6">
          <FormGroup className="mb-2" label="Type">
            <Select options={[
              { value: 'ap', label: '1-4 FAMILY UNITS' },
              { value: 'none', label: 'none' }
            ]}>
            </Select>
          </FormGroup>
        </Col>
        <Col sm="12">
          <FormGroup className="mb-2" label="Street">
            <Input type="text" />
          </FormGroup>
        </Col>
        <Col sm="12" md="6">
          <FormGroup className="mb-2" label="City">
            <Input type="text" />
          </FormGroup>
        </Col>
        <Col sm="6" md="3">
          <FormGroup className="mb-2" label="State">
            <Select options={[
              { value: 'in', label: 'IN' },
              { value: 'none', label: 'none' }
            ]}>
            </Select>
          </FormGroup>
        </Col>
        <Col sm="6" md="3">
          <FormGroup className="mb-2" label="ZIP Code">
            <MaskedInput
              mask={zipCodeMask}
              type='zipCode'
              icon={faMapPin}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup className="mb-1" label="Is the Property more than 50% Owner Occupied?">
            <Radio className="d-inline-block mr-1" name="deposit" value="yes">Yes</Radio>
            <Radio className="d-inline-block" name="deposit" value="no">No</Radio>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup className="mb-2" label="Description">
            <Textarea rows="5" />
          </FormGroup>
        </Col>
        <Col sm="12" md="6" lg="3">
          <FormGroup className="mb-2" label="Current Value">
            <MaskedInput type="number" icon={faDollarSign} />
          </FormGroup>
        </Col>
        <Col sm="12" md="6" lg="3">
          <FormGroup className="mb-2" label="Current Loan">
            <MaskedInput type="number" icon={faDollarSign} />
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup className="mb-2" label="Owner">
            <Input type="text" />
          </FormGroup>
        </Col>
      </Row>
      <ConfirmDeleteButton confirmText="Are you sure you want to delete this collateral?" />
    </FormSection>
    )
  }

  printLoans() {
    return (
      this.state.allSections.map((el, index) => {
        return el(index);
      })
    )
  }

  addNewLoan() {
    const updatedSection = [...this.state.allSections];
    updatedSection.push(this.newSectionLoan);
    this.setState({
      allSections: updatedSection
    });
  }


  render(){
    
    return(
      <div>
      <NavSteps className="mb-2" steps={[
        { text: 'Info', path: '/views/pages/1' },
        { text: 'Request', path: '/views/pages/2' },
        { text: 'Borrower', path: '/views/pages/3' },
        { text: 'Business References', path: '/views/pages/5' },
        { text: 'Collateral', path: '#' },
      ]} />
      <Section title="Collateral to Secure Your Business Loan">
        {this.printLoans()}
      </Section>

      <AddFormSectionButton
        isClicked={this.addNewLoan}
        className="mb-4"
        tooltipText="Add another collateral">Add new collateral</AddFormSectionButton>

      <Section>
        <p className="body-2 mb-2">
          Click Continue to enter Business Obligations and Business Deposits.
            </p>
        <ActionsWrapper className="mb-1">
          <NavLink to={`/views/pages/7`}><Button>Continue</Button></NavLink>
        </ActionsWrapper>
      </Section>
    </div>
    )
  }
}

export default PageSix;