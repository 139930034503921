import React from 'react';
import Highlight from 'react-highlight';
import { ConfirmDeleteButton } from '../../lib';

const confirmDeleteButton = () => {

    return <div>
        <h1 className="mb-3">ConfirmDeleteButton</h1>

        <h2 className="mb-2">Description</h2>
        <p className="mb-4">This is component that enables you to delete after confirm.</p>

        <div className="mb-2">
            <ConfirmDeleteButton confirmText="Are you sure you want to delete this item?"
                isDeleted={e => console.log(e)} />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { ConfirmDeleteButton } from 'vsg-design-system/ConfirmDeleteButton';

const Example = (props) => {
    return (
    <div>
        <ConfirmDeleteButton confirmText="Are you sure you want to delete this item?"
            isDeleted={e => console.log(e)} />
    </div>
    );
}
export default Example; 
    `}
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>confirmText</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the confirm message</td>
                </tr>
                <tr>
                    <td>isDeleted</td>
                    <td>function</td>
                    <td className="text-center">-</td>
                    <td>Callback for when the "yes" button is clicked</td>
                </tr>
            </tbody>
        </table>

    </div>;
}

export default confirmDeleteButton;
