import React from 'react';
import Highlight from 'react-highlight';
import { Button, Modal, Row, Col } from '../../lib';

class ModalDoc extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showFirstModal: false,
            showSecondModal: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    onModalSubmit() {
        console.log('Submit from modal');
    }

    toggleModal(propName, value) {
        this.setState({
            [propName]: !value
        });
    }

    render() {
        return (
            <div>
                <h1 className='mb-3'>Modal</h1>

                <h2 className='mb-2'>Description</h2>
                <p className='mb-4'>The Modal component is a dialog box/popup window that is displayed on top of the current page.</p>
                
                <Row className="mb-2">
                    <Col lg='3' className="mb-1">
                        <Button onClick={() => { this.toggleModal('showFirstModal', this.state.showFirstModal) }} >Modal</Button>
                        <Modal title="Modal example 1"
                            onClose={() => { this.toggleModal('showFirstModal', this.state.showFirstModal) }}
                            isVisible={this.state.showFirstModal}>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                              It has survived not only five centuries, but also the leap into electronic typesetting,
                              remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                              containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                              including versions of Lorem Ipsum</p>
                        </Modal>
                    </Col>
                    <Col lg='3' className="mb-1">
                        <Button onClick={() => { this.toggleModal('showSecondModal', this.state.showSecondModal) }} >Modal 2</Button>
                        <Modal isVisible={this.state.showSecondModal}
                            onClose={() => { this.toggleModal('showSecondModal', this.state.showSecondModal) }}
                            parentClass='parent-modal-2'
                            title="Modal example 2"
                            onSubmit={this.onModalSubmit}
                            closeBtnName='Close'
                            actionBtnName='Agree'>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                               Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                               took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                               centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                               It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                               and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                        </Modal>
                    </Col>
                </Row>
                <div className='mb-2'>

                </div>
                <Highlight className="JS mb-4">
                    {
                        `
import React from 'react';
import { Row } from 'vsg-design-system/Row';
import { Col } from 'vsg-design-system/Col';
import { Modal } from 'vsg-design-system/Modal';
import { Button } from 'vsg-design-system/Button';
    
class Example extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showFirstModal: false,
            showSecondModal: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    onModalSubmit() {
        console.log("Submit from modal");
    }

    toggleModal(propName, value) {
        this.setState({
            [propName]: !value
        });
    }

    render(){
        return(
            <Row className="mb-2">
                <Col lg='3'>
                    <Button onClick={() => { this.toggleModal("showFirstModal", this.state.showFirstModal) }} >Modal</Button>
                    <Modal title="Modal example 1"
                           isVisible={this.state.showFirstModal}>
                           onClose={() => { this.toggleModal("showFirstModal", this.state.showFirstModal) }}
                           <p>
                              Content...
                           </p>
                    </Modal>
                </Col>
                <Col lg='3'>
                    <Button onClick={() => { this.toggleModal("showSecondModal", this.state.showSecondModal) }}>Modal 2</Button>
                    <Modal title="Modal example 2"
                           isVisible={this.state.showSecondModal}
                           onClose={() => { this.toggleModal("showSecondModal", this.state.showSecondModal) }}
                           onSubmit={this.onModalSubmit}
                           parentClass="parent-modal-2"
                           closeBtnName="Close"
                           actionBtnName="Agree">
                           <p>
                              Content...
                           </p>
                    </Modal>
                </Col>
            </Row>
        )
    }
}

export default Example; 
    `
                    }
                </Highlight>
                
                <table className="doc-table mb-3">
                    <thead>
                        <tr>
                            <th colSpan="4">Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>This prop is <strong>required</strong>. Set title to the modal.</td>
                        </tr>
                        <tr>
                            <td>isVisible</td>
                            <td>boolean</td>
                            <td className='text-center'>-</td>
                            <td>Set modal visibility.</td>
                        </tr>
                        <tr>
                            <td>onClose</td>
                            <td>function</td>
                            <td className='text-center'>-</td>
                            <td>Callback to execute when the modal is closed.</td>
                        </tr>
                        <tr>
                            <td>onSubmit</td>
                            <td>function</td>
                            <td className='text-center'>-</td>
                            <td>Callback to execute when the modal CTA is submitted/confirmed.</td>
                        </tr>
                        <tr>
                            <td>parentClass</td>
                            <td>string</td>
                            <td>The modal will be appended to the document's body</td>
                            <td>Set class to modal wrapper and append the modal in it.</td>
                        </tr>
                        <tr>
                            <td>closeBtnName</td>
                            <td>string</td>
                            <td>CANCEL</td>
                            <td>Set name to the modal cancel button.</td>
                        </tr>
                        <tr>
                            <td>actionBtnName</td>
                            <td>string</td>
                            <td>OK</td>
                            <td>Set name to the modal submit button.</td>
                        </tr>
                        <tr>
                            <td>isClosable</td>
                            <td>boolean</td>
                            <td>true</td>
                            <td>Showing the buttons for closing.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ModalDoc;