import React from 'react';
import { NavLink } from 'react-router-dom';

import {
    Button,
    NavSteps,
    Section,
    FormGroup,
    Select,
    Datepicker,
    Radio,
    Input,
    ActionsWrapper,
    Checkbox,
    Row, 
    Col
} from '../../lib/index';

const pageEight = props => {
    const displayNone = {
        display: 'none',
    };
    const clicked = (event) => {
        if(event.target.value === 'Other'){
            document.querySelector('.other').style.display = 'block';
        }else{
            document.querySelector('.other').style.display = 'none';
        }  
    }
    return (
        <div>
            <NavSteps className="mb-2" steps={[
                { text: 'Info', path: '/views/pages/1' },
                { text: 'Request', path: '/views/pages/2' },
                { text: 'Borrower', path: '/views/pages/3' },
                { text: 'Business References', path: '/views/pages/5' },
                { text: 'Collateral', path: '/views/pages/6' },
                { text: 'Debts / Deposits', path: '/views/pages/7' },
                { text: 'Declarations', path: '#' },
            ]} />
            <Section title="Declarations">
                <h6 className="mb-2">Please answer the questions below.</h6>
                <Row>
                    <Col sm="12">
                        <FormGroup className="mb-2" label="Do you agree to have your credit pulled?">
                            <Radio className="d-inline-block mr-1" name="credit" value="yes">Yes</Radio>
                            <Radio className="d-inline-block" name="credit" value="no">No</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                        <FormGroup className="mb-2" label="Business Tax Returns have been filed through date">
                            <Datepicker className="mb-3" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6" lg="4">
                        <FormGroup className="mb-2" label="If you have a banking relationship with a current banker, please select their name from the drop down.">
                            <Select options={[
                                { value: 'Elena officer', label: 'Elena officer' },
                                { value: 'none', label: 'none' }
                            ]}>
                            </Select>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup className="mb-2" label="Do you consent to receiving communication electronically via e-mail? If you do not, the online application cannot be processed, Please contact us at 555-555-5555 for assistance with obtaining a loan.">
                            <Radio className="d-inline-block mr-1" name="communication" value="yes">Yes</Radio>
                            <Radio className="d-inline-block" name="communication" value="no">No</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup className="mb-2" label="Are you an Executive Officer of Ovation Financial?">
                            <Radio className="d-inline-block mr-1" name="officer" value="yes">Yes</Radio>
                            <Radio className="d-inline-block" name="officer" value="no">No</Radio>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup className="mb-2" label="How did you hear about this online loan application?">
                            <Radio name="hear" value="Customer Referral" onClick={clicked}>Customer Referral</Radio>
                            <Radio name="hear" value="E-mail" onClick={clicked}>E-mail</Radio>
                            <Radio name="hear" value="Employee" onClick={clicked}>Employee - Banker</Radio>
                            <Radio name="hear" value="Other" onClick={clicked}>Other</Radio>
                            <Row>
                                <Col sm="12" md="6" lg="4">
                                    <Input className="other" style={displayNone}/>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </Section>
            <Section title="Disclosures">
                <p className="mb-1">Please review the below Disclosures. </p>
                <p className="mb-1"><a href="/views/pages/8">Click to view and read</a></p>
                <Checkbox className="mb-1" name="vehicle1" value="Bike">I acknowledge that I have received and viewed these documents</Checkbox>
            </Section>
            <Section>
                <p className="body-2 mb-2">
                    Click Continue to review Terms & Conditions.
                 </p>
                <ActionsWrapper className="mb-1">
                    <NavLink to={`/views/pages/9`}><Button>Continue</Button></NavLink>
                </ActionsWrapper>
            </Section>
        </div>
    );
}

export default pageEight;