import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ScrollIntoView from './ScrollTop';

import {
    Header,
    Footer,
    Container,
    Tooltip,
} from '../lib/index';

import PageOne from './pages/PageOne';
import PageTwo from './pages/PageTwo';
import PageThree from './pages/PageThree';
import PageFour from './pages/PageFour';
import PageFive from './pages/PageFive';
import PageSix from './pages/PageSix';
import PageSeven from './pages/PageSeven';
import PageEight from './pages/PageEight';
import PageNine from './pages/PageNine';
import PageTen from './pages/PageTen';
import PageEleven from './pages/PageEleven';
import PageTwoValidation from './pages/PageTwoValidation';

class DemoPages extends Component {

    render() {

        return (
            <div>
                <Header
                    title="0800 800 800"
                    subTitle="Client Text"
                    logo="https://pik-oplenac.com/wp-content/plugins/allsecure_woo/assets/images/light/unicredit.svg"
                    links={[
                        { text: 'FAQs', path: '#' },
                        { text: 'Sign Out', path: '#' }
                    ]} />

                <Container>
                    <ScrollIntoView>
                        <Switch>
                            <Route path="/views/pages/11" component={PageEleven} />
                            <Route path="/views/pages/10" component={PageTen} />
                            <Route path="/views/pages/9" component={PageNine} />
                            <Route path="/views/pages/8" component={PageEight} />
                            <Route path="/views/pages/7" component={PageSeven} />
                            <Route path="/views/pages/6" component={PageSix} />
                            <Route path="/views/pages/5" component={PageFive} />
                            <Route path="/views/pages/4" component={PageFour} />
                            <Route path="/views/pages/3" component={PageThree} />
                            <Route path="/views/pages/13" component={PageTwoValidation} />
                            <Route path="/views/pages/2" component={PageTwo} />
                            <Route path="/views" component={PageOne} />
                        </Switch>
                    </ScrollIntoView>
                    <Footer text="© Copyright or other text" />
                </Container>
                <Tooltip place="top" effect="solid" />
            </div>
        );
    }
}

export default DemoPages;