import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Button,
  NavSteps,
  Section,
  FormGroup,
  Select,
  Radio,
  ActionsWrapper,
  Row, 
  Col
} from '../../lib/index';

const pageOne = () => {
  return (
    <div>
      <NavSteps className="mb-2" steps={[
        { text: 'Info', path: '#' },
      ]} />
      <p className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
          took a galley of type and scrambled it to make a type specimen book. It has survived not only five
          centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
              and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
      <Section title="Application Information">
        <Row>
          <Col lg="3" md="6">
            <FormGroup className="mb-2" label='Business (Exsisting or new)' hoverHelp="Lorem Ipsum is simply dummy text">
              <Select options={[
                { value: 'newBusiness', label: 'New business' },
                { value: 'exsisting', label: 'Exsisting' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6">
            <FormGroup className="mb-2" label="Are you the Sole owner?">
              <Radio className="d-inline-block mr-1" name="deposit" value="yes">Yes</Radio>
              <Radio className="d-inline-block" name="deposit" value="no">No</Radio>
            </FormGroup>
          </Col>
        </Row>
      </Section>
      <Section>
        <p className="body-2 mb-2">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
            </p>
        <ActionsWrapper className="mb-1">
          <NavLink to={`/views/pages/2`}><Button>Continue</Button></NavLink>
        </ActionsWrapper>
      </Section>

    </div>
  );
}

export default pageOne;