import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import 'highlight.js/styles/dracula.css';
import '../docs-styles.scss';

import Sidebar from './Sidebar/Sidebar';
import DocsContainer from './section-layout/DocContainer/DocContainer';
import {
    Alert,
    Button,
    Datepicker,
    PricingCard,
    Forms,
    Introduction,
    SpacingUtils,
    ActionsWrapper,
    Toast as ToastPage,
    Loader,
    Testing,
    Modal,
    Header,
    Footer,
    Containers,
    Tooltip as TooltipPage,
    NavSteps,
    ReactAux,
    AddFormSectionButton,
    ConfirmDeleteButton,
    ProgressBar,
    Typography,
    DisplayUtils,
    TextUtils,
    WidthUtils,
    NavMenu,
    InfoCard,
    FileUploadCardDocs,
    Banner,
    FileUploadHistoryCard,
    TimeOutMessage
} from './sections';

import { Tooltip, Toast } from '../lib';

// Init toast
Toast.configure();

class Docs extends Component {

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    render() {
        return <div>
            <Sidebar />
            <DocsContainer>
                <Switch>
                    <Route path="/docs/alert" component={Alert} />
                    <Route path="/docs/button" component={Button} />
                    <Route path="/docs/containers" component={Containers} />
                    <Route path="/docs/datepicker" component={Datepicker} />
                    <Route path="/docs/footer" component={Footer} />
                    <Route path="/docs/pricingCard" component={PricingCard} />
                    <Route path="/docs/forms" component={Forms} />
                    <Route path="/docs/header" component={Header} />
                    <Route path="/docs/introduction" component={Introduction} />
                    <Route path="/docs/actionsWrapper" component={ActionsWrapper} />
                    <Route path="/docs/loader" component={Loader} />
                    <Route path="/docs/infoCard" component={InfoCard} />
                    <Route path="/docs/modal" component={Modal} />
                    <Route path="/docs/toast" component={ToastPage} />
                    <Route path="/docs/testing" component={Testing} />
                    <Route path="/docs/tooltip" component={TooltipPage} />
                    <Route path="/docs/navMenu" component={NavMenu} />
                    <Route path="/docs/navSteps" component={NavSteps} />
                    <Route path="/docs/reactAux" component={ReactAux} />
                    <Route path="/docs/fileUploadHistoryCard" component={FileUploadHistoryCard} />
                    <Route path="/docs/addFormSectionButton" component={AddFormSectionButton} />
                    <Route path="/docs/confirmDeleteButton" component={ConfirmDeleteButton} />
                    <Route path="/docs/progressBar" component={ProgressBar} />
                    <Route path="/docs/Typography" component={Typography} />
                    <Route path="/docs/utilities/spacing" component={SpacingUtils} />
                    <Route path="/docs/utilities/display" component={DisplayUtils} />
                    <Route path="/docs/utilities/text" component={TextUtils} />
                    <Route path="/docs/utilities/width" component={WidthUtils} />
                    <Route path="/docs/fileUploadCard" component={FileUploadCardDocs} />
                    <Route path="/docs/banner" component={Banner} />
                    <Route path="/docs/time-out-message" component={TimeOutMessage} />
                </Switch>
            </DocsContainer>
            <Tooltip place="top" effect="solid" />
        </div>
    }
}

export default Docs;