import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logo.png';
import './sidebar.scss';

import LinkItem from './LinkItem/LinkItem';

const sidebar = (props) => {

    return <div className="doc-sidebar">
        <div className="space"></div>
        <div className="menu-header">
            <Link to="/">
                <img style={{ height: '45px' }} src={logo} alt="VSG" />
            </Link>
            <h1>Design System</h1>
            <span>v. 4.0.9</span>
        </div>
        <div className="menu-links">
            <LinkItem title="Getting started" links={[
                { title: 'Introduction', path: '/docs/introduction' },
            ]} />
            <LinkItem title="Typography" links={[
                { title: 'Basic', path: '/docs/typography' }
            ]} />
            <LinkItem title="Components" links={[
                { title: 'AddFormSectionButton', path: '/docs/addFormSectionButton' },
                { title: 'Alert', path: '/docs/alert' },
                { title: 'Banner', path: '/docs/banner' },
                { title: 'Button', path: '/docs/button' },
                { title: 'ConfirmDeleteButton', path: '/docs/confirmDeleteButton' },
                { title: 'Datepicker', path: '/docs/datepicker' },
                { title: 'FileUploadCard', path: '/docs/fileUploadCard' },
                { title: 'FileUploadHistoryCard', path: '/docs/fileUploadHistoryCard' },
                { title: 'Forms', path: '/docs/forms' },
                { title: 'InfoCard', path: '/docs/infoCard' },
                { title: 'Loader', path: '/docs/loader' },
                { title: 'PricingCard', path: '/docs/pricingCard' },
                { title: 'ProgressBar', path: '/docs/progressBar' },
                { title: 'ReactAux', path: '/docs/reactAux' },
                { title: 'Modal', path: '/docs/modal' },
                { title: 'NavMenu', path: '/docs/navMenu' },
                { title: 'NavSteps', path: '/docs/navSteps' },
                { title: 'TimeOutMessage', path: '/docs/time-out-message' },
                { title: 'Toast', path: '/docs/toast' },
                { title: 'Tooltip', path: '/docs/tooltip' },
            ]} />
            <LinkItem title="Layout Components" links={[
                { title: 'ActionsWrapper', path: '/docs/actionsWrapper' },
                { title: 'Containers', path: '/docs/containers' },
                { title: 'Footer', path: '/docs/footer' },
                { title: 'Header', path: '/docs/header' }
            ]} />
            <LinkItem title="Utilities" links={[
                { title: 'Display', path: '/docs/utilities/display' },
                { title: 'Text', path: '/docs/utilities/text' },
                { title: 'Spacing', path: '/docs/utilities/spacing' },
                { title: 'Width', path: '/docs/utilities/width' },
                { title: 'Testing', path: '/docs/testing' }
            ]} />
        </div>
    </div>
}

export default sidebar;