import React from 'react';
import { NavLink } from 'react-router-dom';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  NavSteps,
  Section,
  FormGroup,
  Select,
  MaskedInput,
  Textarea,
  Radio,
  Input,
  ActionsWrapper,
  Row,
  Col,
  MultipleFormGroup
} from '../../lib/index';

const pageTwo = props => {
  return (
    <div>
      <NavSteps className="mb-2" steps={[
        { text: 'Info', path: '/views/pages/1' },
        { text: 'Request', path: '#' },
      ]} />
      <Section title="Purpose and Product">
        <Row>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Purpose of Loan" hoverHelp="Lorem Ipsum is simply dummy text">
              <Select options={[
                { value: 'workingCapital', label: 'Working Capital' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Product Type">
              <Select options={[
                { value: 'creditCard', label: 'Credit Card' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-2" label="Amount Requested">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup className="mb-2" label="Specific Reason">
              <Textarea rows="5"></Textarea>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" isBold={true} label="Location of Business">
              <Select options={[
                { value: 'ak', label: 'AK' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" isBold={false} label="County of Business">
              <Select options={[
                { value: 'ak', label: 'Aleutians West Census Area' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-2" isBold={true} label="Existing Deposit Customer?">
              <Radio className="d-inline-block mr-1" name="deposit" value="yes">Yes</Radio>
              <Radio className="d-inline-block" name="deposit" value="no">No</Radio>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <h6 className="mb-1">Date Business Started</h6>
          </Col>
          <Col lg="3">
            <MultipleFormGroup
              className="px-0"
              formGroupProps={{
                label: 'Choose expiry date'
              }}
              groupColProps={{
                xs: ''
              }}
              groupElements={[
                {
                  element: <Input className="invalid" type="number" placeholder="YYYY" />,
                  elementColProps: {
                    lg: '6',
                    className: 'mb-2'
                  },
                  isValid: false,
                  validationMessage: "Invalid Year"
                },
                {
                  element: <Select
                    className="invalid"
                    options={[
                      { value: 'Jan', label: 'Jan' },
                      { value: 'Feb', label: 'Feb' },
                      { value: 'Mar', label: 'Mar' },
                      { value: 'Apr', label: 'Apr' },
                      { value: 'May', label: 'May' },
                      { value: 'June', label: 'June' },
                      { value: 'July', label: 'July' },
                      { value: 'Aug', label: 'Aug' },
                      { value: 'Sept', label: 'Sept' },
                      { value: 'Oct', label: 'Oct' },
                      { value: 'Nov', label: 'Nov' },
                      { value: 'Dec', label: 'Dec' },
                    ]}
                  />,
                  elementColProps: {
                    lg: '6',
                    className: 'mb-2'
                  },
                  isValid: false,
                  validationMessage: "Invalid Month"
                }
              ]}
            />
          </Col>

          <MultipleFormGroup
            formGroupProps={{
              isRequired: true,
              label: 'Multiple From group example'
            }}
            groupColProps={{
              lg: '9'
            }}
            groupElements={[
              {
                element: <Input className="invalid" />,
                elementColProps: {
                  lg: '3',
                  className: 'mb-2'
                },
                validationMessage: "Invalid Input",
                isValid: false,
              },
              {
                element: <Select
                  className="invalid"
                  options={[
                    { value: 'one', label: 'Option One' },
                    { value: 'two', label: 'Option Two' },
                    { value: 'other', label: 'Other' },
                  ]}
                />,
                elementColProps: {
                  lg: '4',
                  className: 'mb-2'
                },
                validationMessage: "Invalid Select",
                isValid: false,
              },
              {
                element: <Input />,
                elementColProps: {
                  lg: '5',
                  className: 'mb-2'
                }
              },
            ]}
          />

        </Row>
      </Section>
      <Section>
        <p className="body-2 mb-2">
          Owner and Business information will be requested on the next page.
        </p>
        <ActionsWrapper className="mb-1">
          <NavLink to={`/views/pages/13`}><Button>Continue</Button></NavLink>
        </ActionsWrapper>
      </Section>
    </div>
  );
}

export default pageTwo;