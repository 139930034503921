import React from 'react';
import Highlight from 'react-highlight';
import { AddFormSectionButton } from '../../lib';

const addFormSectionButton = () => {

    return <div>
        <h1 className="mb-3">AddFormSectionButton</h1>

        <h2 className="mb-2">Description</h2>
        <p className="mb-4">A button that shows a line and tooltip above it. It is useful when a form could be extended
        with additional sections.</p>

        <div className="mb-2">
            <AddFormSectionButton className="mb-3" tooltipText="Add another box"
                isClicked={e => console.log(e)}>Add Box</AddFormSectionButton>
            <AddFormSectionButton isClicked={e => console.log(e)}>Add Box without Tooltip</AddFormSectionButton>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { AddFormSectionButton } from 'vsg-design-system/AddFormSectionButton';

const Example = (props) => {
    return (
    <div>
        <AddFormSectionButton className="mb-3" tooltipText="Add another box"
            isClicked={e => console.log(e)}>Add Box</AddFormSectionButton>
        <AddFormSectionButton isClicked={e => console.log(e)}>Add Box without Tooltip</AddFormSectionButton>
    </div>
    );
}
export default Example; 
    `}
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan="4">Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>tooltipText</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Enable and set text for tooltip</td>
                </tr>
                <tr>
                    <td>isClicked</td>
                    <td>function</td>
                    <td className="text-center">-</td>
                    <td>Callback for when the button is clicked</td>
                </tr>
            </tbody>
        </table>

    </div>;
}

export default addFormSectionButton;
