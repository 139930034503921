import React from 'react';
import { NavLink } from 'react-router-dom';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  NavSteps,
  Section,
  FormGroup,
  Select,
  MaskedInput,
  Textarea,
  Input,
  ActionsWrapper,
  FormSection,
  ConfirmDeleteButton,
  AddFormSectionButton,
  Row,
  Col,
  TooltipHelper
} from '../../lib/index';


class PageSeven extends React.Component {

  constructor(props) {
    super();
    this.state = {
      allSections: [this.newSectionDeposit],
      allSectionsObligations: [this.newSectionObligation]
    };

    this.addNewDeposit = this.addNewDeposit.bind(this);
    this.printDeposits = this.printDeposits.bind(this);
    this.addNewObligation = this.addNewObligation.bind(this);
    this.printObligations = this.printObligations.bind(this);
  }

  componentDidUpdate() {
    TooltipHelper.rebuild();
  }

  newSectionDeposit(index) {
    return (
      <FormSection className="mb-2" key={index}>
        <h6 className="mb-2">{index + 1}.Business Deposit Relationships</h6>
        <Row>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Deposit Type">
              <Select options={[
                { value: 'ap', label: 'Money Maker' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Current Balance">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup className="mb-2" label="Bank Name">
              <Input type="text" />
            </FormGroup>
          </Col>
        </Row>
        <ConfirmDeleteButton confirmText="Are you sure you want to delete this collateral?" />
      </FormSection>
    )
  }

  newSectionObligation(index) {
    return (
      <FormSection className="mb-2" key={index}>
        <h6 className="mb-2">{index + 1}.Business obligations</h6>
        <Row>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Creditor" hoverHelp="Creditor name">
              <Input type="text" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Loan Type">
              <Select options={[
                { value: 'ap', label: 'Mortgage' },
                { value: 'none', label: 'none' }
              ]}>
              </Select>
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Current Balance">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="3">
            <FormGroup className="mb-2" label="Monthly Payment">
              <MaskedInput type="number" icon={faDollarSign} />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="mb-2" label="Collateral">
              <Textarea rows="5" />
            </FormGroup>
          </Col>
        </Row>
        <ConfirmDeleteButton confirmText="Are you sure you want to delete this collateral?" />
      </FormSection>
    )
  }

  printObligations() {
    return (
      this.state.allSectionsObligations.map((el, index) => {
        return el(index);
      })
    )
  }

  printDeposits() {
    return (
      this.state.allSections.map((el, index) => {
        return el(index);
      })
    )
  }

  addNewDeposit() {
    const updatedSection = [...this.state.allSections];
    updatedSection.push(this.newSectionDeposit);
    this.setState({
      allSections: updatedSection
    });
  }

  addNewObligation() {
    const updatedSection = [...this.state.allSectionsObligations];
    updatedSection.push(this.newSectionObligation);
    this.setState({
      allSectionsObligations: updatedSection
    });
  }

  render() {

    return (
      <div>
        <NavSteps className="mb-2" steps={[
          { text: 'Info', path: '/views/pages/1' },
          { text: 'Request', path: '/views/pages/2' },
          { text: 'Borrower', path: '/views/pages/3' },
          { text: 'Business References', path: '/views/pages/5' },
          { text: 'Collateral', path: '/views/pages/6' },
          { text: 'Debts / Deposits', path: '#' },
        ]} />
        <Section title="Business obligations">
          {this.printObligations()}
        </Section>
        <AddFormSectionButton
          className="mb-4"
          isClicked={this.addNewObligation}
          tooltipText="Add another debt">Add new debt</AddFormSectionButton>
        <Section title="Business Deposit Relationships">
          {this.printDeposits()}
        </Section>
        <AddFormSectionButton
          isClicked={this.addNewDeposit}
          className="mb-4"
          tooltipText="Add another deposit">Add new deposit</AddFormSectionButton>
        <Section>
          <p className="body-2 mb-2">
            Click Continue to review the terms and conditions.
              </p>
          <ActionsWrapper className="mb-1">
            <NavLink to={`/views/pages/8`}><Button>Continue</Button></NavLink>
          </ActionsWrapper>
        </Section>
      </div>
    );
  }
}

export default PageSeven;