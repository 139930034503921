import React, { Component } from 'react';
import Highlight from 'react-highlight';
import { Datepicker, DatepickerUtils } from '../../lib';

class DatepickerDocs extends Component {

    render() {
        return (
            <div>
                <h1 className='mb-3'>Datepicker</h1>

                <h2 className='mb-2'>Description</h2>
                <p className='mb-2'>The Datepicker component in QD Design System is a custom form control that handles date inputs,
                    using an interactive calendar. It is built on top of  <a href="https://www.telerik.com/kendo-react-ui/components/dateinputs/datepicker/" target="_blank" rel="noopener noreferrer">KendoReact Datepicker</a>.</p>
                <p className='mb-4'>The DatepickerUtils object contains helpful functions for date-related cases. See the <a href="#api-datepicker-utils">API reference</a> below.</p>

                <h5 className='mb-2'>Default</h5>
                <Datepicker
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange)
                    }}
                    className="mb-2"
                />
                <Datepicker
                    placeholder="MM/DD/YY"
                    disabled
                    value={new Date()}
                    className="mb-3"
                />

                <h5 className='mb-2'>DateTimePicker</h5>
                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange)
                    }}
                    value={DatepickerUtils.dateFromString('080920', 'MM/DD/YYYY')}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    className="mb-2"
                />

                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    dateTime={true}
                    disabled
                    value={new Date()}
                    className="mb-3"
                />

                <h5 className='mb-2'>Datepicker with range value</h5>
                <Datepicker
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange)
                    }}
                    rangeStart={new Date("2020/03/10")}
                    rangeEnd={new Date("2020/08/10")}
                    className="mb-2"
                />

                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange)
                    }}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    rangeStart={new Date("2020/03/10")}
                    rangeEnd={new Date("2020/08/10")}
                    className="mb-3"
                />

                <Highlight className="React mb-4">
                    {
                        `
import React from 'react';
import { Datepicker, DatepickerUtils } from 'vsg-design-system/Datepicker';

class Example extends Component {

    render () {
        return (
            <div>
                /* Default */
                <Datepicker
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange)
                    }}
                    className="mb-2"
                />
                <Datepicker
                    placeholder="MM/DD/YY"
                    disabled
                    value={new Date()}
                    className="mb-3"
                />

                /* DateTimePicker */
                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange)
                    }}
                    value={DatepickerUtils.dateFromString('080920', 'MM/DD/YYYY')}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    className="mb-2"
                />

                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    dateTime={true}
                    disabled
                    value={new Date()}
                    className="mb-3"
                />

                /* Datepicker with range value */
                <Datepicker
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange)
                    }}
                    rangeStart={new Date("2020/03/10")}
                    rangeEnd={new Date("2020/08/10")}
                    className="mb-2"
                />

                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid, isDateInRange) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange)
                    }}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    rangeStart={new Date("2020/03/10")}
                    rangeEnd={new Date("2020/08/10")}
                    className="mb-3"
                />
            </div>
        }
    );
}

export default Example; 
    `}
                </Highlight>

                <h6 className="mb-3">Datepicker API</h6>
                <table className="doc-table mb-3">
                    <thead>
                        <tr>
                            <th colSpan="4">Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>dateTime</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Configures the datepicker to contain a time picker.</td>
                        </tr>
                        <tr>
                            <td>format</td>
                            <td>string</td>
                            <td>"MM/DD/YYYY"</td>
                            <td>Sets the date format.</td>
                        </tr>
                        <tr>
                            <td>placeholder</td>
                            <td>string</td>
                            <td>'MM/DD/YYYY'</td>
                            <td>Sets the placeholder in the input.</td>
                        </tr>
                        <tr>
                            <td>rangeStart</td>
                            <td>Date</td>
                            <td className='text-center'>-</td>
                            <td>Sets the datepicker starting date.</td>
                        </tr>
                        <tr>
                            <td>rangeEnd</td>
                            <td>Date</td>
                            <td className='text-center'>-</td>
                            <td>Sets the datepicker end date.</td>
                        </tr>
                        <tr>
                            <td>onInvalidDate</td>
                            <td>function(isValid: boolean, isDateInRange: boolean)</td>
                            <td className='text-center'>-</td>
                            <td>Function, triggered when you try to set an invalid date or a date out of range.</td>
                        </tr>
                        <tr>
                            <td>value</td>
                            <td>Date</td>
                            <td className='text-center'>-</td>
                            <td>Sets datepicker and input value.</td>
                        </tr>
                        <tr>
                            <td>onChange</td>
                            <td>function</td>
                            <td className='text-center'>-</td>
                            <td>A callback, executed after the datepicker date is changed or the input field is blurred.</td>
                        </tr>
                        <tr>
                            <td>all native input attributes</td>
                            <td className='text-center'>-</td>
                            <td className='text-center'>-</td>
                            <td>You can use all native input attributes like: "disabled", "name", etc.</td>
                        </tr>
                    </tbody>
                </table>

                <h6 id="api-datepicker-utils" className="mb-3">DatepickerUtils API</h6>
                <table className="doc-table mb-3">
                    <thead>
                        <tr>
                            <th colSpan="4">Functions</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Params</strong>
                            </td>
                            <td>
                                <strong>Return type</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>dateFromString</td>
                            <td>(date:string, format?:string)</td>
                            <td>Date</td>
                            <td>Converts string to date. The default format is "MM/DD/YYYY".</td>
                        </tr>
                        <tr>
                            <td>formatedDateString</td>
                            <td>(date:Date, format:string)</td>
                            <td>string</td>
                            <td>Applies the target formatting to the given date.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default DatepickerDocs;